/* eslint-disable react/jsx-no-target-blank */
import React, { useState } from 'react';
// import { apiClient } from '../apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import Navigation from '../Components/common/Navigation';
import BackButton from '../Components/common/BackButton';

import { config } from '../config';
import { Link } from 'react-router-dom';
import { SVG_PERSON } from '../Helpers/icons';
import { UserGroups } from '../Components/common/UserGroup';

export default function Contacts({ contactId }) {
	const [activeNavItem, setActiveNavItem] = useState(0);
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);
	const { users } = state;

	const user = users.items.find((i) => parseInt(i.id) === parseInt(contactId));
	// const baseUrl = '/contacts';

	const lastNames = new Set(
		(users?.items ?? [])
			.reduce((result, u) => {
				if (u.last_name) {
					result.push(u.last_name.toUpperCase().substring(0, 1));
				}
				return result;
			}, [])
			.sort()
	);

	const sortedUsers = (users?.items ?? []).sort((a, b) => {
		return a.last_name.localeCompare(b.last_name, config.localeCompare.locale);
	});

	var prevUser = false;

	return (
		<div>
			<Navigation />
			{contactId && <Contact user={user} />}
			{!contactId && (
				<section>
					<div className="c-page-header">
						<h1>Yhteystiedot </h1>
						<p>Täältä löydät TRP Groupin koko henkilöstön yhteystiedot.</p>
					</div>

					<div className="c-page contacts desktop">
						<BackButton />
						<div className="c-contacts">
							<div className="nav">
								<div className="inner">
									{[...lastNames].map((l, index) => {
										return (
											<a
												key={`nav-${l}`}
												href={`#skip-to-${l}`}
												className={index === activeNavItem ? 'active' : ''}
												onClick={() => setActiveNavItem(index)}
											>
												{l}
											</a>
										);
									})}
								</div>
							</div>
							{users.loading && <Loader />}
							{sortedUsers && (
								<ul>
									{sortedUsers.map((user) => {
										const userLetter = (user?.last_name ?? '')
											.substring(0, 1)
											.toUpperCase();

										const letterAnchor =
											userLetter !==
											(prevUser?.last_name ?? '').substring(0, 1).toUpperCase();

										prevUser = user;

										return (
											<li key={user.id}>
												<ContactLink
													user={user}
													letterAnchor={letterAnchor}
													userLetter={userLetter}
												/>
											</li>
										);
									})}
								</ul>
							)}
							<div className="end-of-list">...</div>
						</div>
					</div>
				</section>
			)}
		</div>
	);
}

export const ContactLink = ({
	user,
	letterAnchor = false,
	userLetter = false,
}) => {
	const baseUrl = '/contacts';
	const hasImg = user?.thumb_id ?? false;
	const firstGroupId = user?.group[0]?.id ?? '';
	return (
		/* TODO: User can belong to multiple groups, how to style? */
		<Link
			className={`c-contacts-item user-group-${firstGroupId}`}
			to={`${baseUrl}/${user.id}`}
		>
			{letterAnchor && (
				<span
					id={`skip-to-${userLetter}`}
					className="letterAnchor"
					style={{ fontSize: '1px' }}
				>
					{userLetter}
				</span>
			)}
			{!hasImg && <SVG_PERSON className={'thumb mini'} />}
			{hasImg && (
				<img
					src={config.apiUrl + '/file/' + hasImg + '/preset=thumb'}
					alt="profile"
					loading="lazy"
					className="thumb mini"
				/>
			)}
			<div>
				<div>
					<span className="last">{user.last_name}</span> {user.first_name}
				</div>
				<br />
				<span>{user.title}</span>
			</div>
			<div className="groups ">
				{user.group.map((i) => (
					<span className={`group user-group-${i.id}`} key={`group-${i.id}`}></span>
				))}
			</div>
		</Link>
	);
};

const Contact = ({ user }) => {
	if (!user) {
		return <Loader className=""></Loader>;
	}

	const hasImg = user?.thumb_id ?? false;
	// const firstGroupId = user?.group[0]?.id ?? '';

	return (
		<section>
			<div className="c-page-header">
				<h1>Yhteystiedot </h1>
				<p>Täältä löydät TRP Groupin koko henkilöstön yhteystiedot.</p>
			</div>
			{/* TODO: User can belong to multiple groups, how to style? */}
			<section
				id={user.slug}
				className={`c-page contact-user `}
			>
				<BackButton />
				<div className="c-person">
					<div className="row">
						<div className="image">
							{!hasImg && <SVG_PERSON className={''} />}
							{hasImg && (
								<img
									src={config.apiUrl + '/file/' + hasImg + '/preset=thumb'}
									alt="profile"
									loading="lazy"
									className="thumb mini"
								/>
							)}
						</div>
						<div className="text">
							<h1>
								<span>{user.last_name}</span> {user.first_name}
							</h1>
							<p className="italic">
								<strong>{user.title}</strong>
								<br />
								{/* TODO: User can belong to multiple groups, how to style? */}
								{/* <UserGroup groupId={user.group_id} /> */}
								<UserGroups groups={user.group} />
							</p>
							<p>
								{!user.is_private && (
									<>
										{user.phone}
										<br />
									</>
								)}
								{user.email}
							</p>
							<div className="buttons">
								{!user.is_private && user.phone && (
									<a
										className="phone"
										target="_blank"
										href={`tel:${user.phone}`}
										rel="noopener"
									>
										<span className="icon"></span>
										Soita
									</a>
								)}
								{user.email && (
									<a
										className="email"
										target="_blank"
										href={`mailto:${user.email}`}
										rel="noopener"
									>
										<span className="icon"></span>
										Lähetä sähköpostia
									</a>
								)}
								{/* <Link to="">Linkedin</Link> */}
							</div>
						</div>
					</div>
				</div>
			</section>
		</section>
	);
};
