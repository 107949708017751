import React, { useState, useRef } from 'react';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';
import { NavTab } from 'react-router-tabs';

import {
	CustomInput,
	// CustomSwitch,
	createHandlers,
} from '../../Components/common/CustomInput';


export default function Debug(props) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { id } = props;	
	const [data, setData] = useState({ title:'test', body:'another test', to:[id] });
	// eslint-disable-next-line no-unused-vars
	const [responseData, setResponseData] = useState({ });
	const formRef = useRef();

	const {
		// handleOnChangeForCheckBoxes,
		handleOnChange,
		// handleOnCustomChange,
		// handleOnChangeArray,
		// handleSwitch,
		// handleOnCKEditorChange,
		// sendFile,
		// sendMedia,
	} = createHandlers(data, setData, apiCall, state);


	const sendNotification = () => {

		const saveData = data;

		apiCall
			.POSTRAW('messages/send-notification', '', saveData)
			.then((response) => {
				console.log(response);
			});
	}


	return (
		<div className="company-form">
			<header>
				<div className="inner">
					<div className="top">
						<h1>
							<span className="category"></span>
							Debugging
						</h1>
					</div>
				</div>
			</header>
			<div className="header-bottom">
				<nav>
					<ul className="max-width">
						<li>
							<NavTab to={`/admin/debug/info`}>Yleisinfo</NavTab>
						</li>
					</ul>
				</nav>
			</div>

			<section>
				<form
					ref={formRef}
					onSubmit={(e) => {
						e.preventDefault();
						e.stopPropagation();
					}}
				>
					<fieldset id="perustiedot">
						<legend>Perustiedot:</legend>
						<div className="input-wrapper">
							<CustomInput
								label={'Vastaanottajan "osoite"'}
								field={'to'}
								useTextarea={true}
								handleOnChange={handleOnChange}
								data={data}
								disabled={true}
								readOnly={true}
							/>
							<CustomInput
								label={'Otsikko'}
								field={'title'}
								handleOnChange={handleOnChange}
								data={data}
							/>
							<CustomInput
								label={'Viestin sisältö'}
								field={'body'}
								useTextarea={true}
								handleOnChange={handleOnChange}
								data={data}
							/>
						</div>
						<button className="test button" onClick={sendNotification}>
							Testaa notifikaation lähetystä
						</button>
					</fieldset>


						<div className="input-wrapper">
							<label >
								<span className="label">Vastausviesti</span>
								<textarea disabled={true} readOnly={true} value={JSON.stringify(responseData ?? {})}></textarea>
							</label>
						</div>
				</form>
			</section>
		</div>
	);
}
