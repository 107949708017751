import React from 'react';

import AppHeader from '../Components/AppHeader';
import RequestPermissionToggle from '../Components/common/RequestPermissionToggle';
import { ICON_CHECK_WHITE_IMG } from '../Helpers/icons';

// import React from 'react';
import TinySlider from 'tiny-slider-react';

// import { ICON_PEN_IMG } from '../Helpers/icons';
// import { config } from '../../config';

export const Carousel = ({ settings, endOnboarding }) => {
	const onChange = (info, eventName) => {
		if (info.index === 4) {
			endOnboarding();
		}
	};
	return (
		<div className="carousel">
			<TinySlider settings={settings} onTransitionEnd={onChange}>
				<div className="slide">
					<div className="slide-content">
						<div className="icon desktop"></div>
						<p>Lisää Terppi puhelimesi Koti-valikkoon nopeaa käyttöä varten.</p>
						<p className="small">
							<a
								href="https://player.vimeo.com/external/527852476.hd.mp4?s=a076ff26f783d001a978084f7176ed1ffd57d42c&profile_id=174"
								target="_blank"
								rel="nofollow noreferrer"
							>
								Katso ohjevideo Android ›
							</a>
						</p>
					</div>
				</div>
				<div className="slide">
					<div className="slide-content">
						<div className="icon">
							<svg
								width="124"
								height="142"
								viewBox="0 0 124 142"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g fill="#48B8FF" fillRule="nonzero">
									<path
										d="M61.5 142c9.662 0 17.5-8.058 17.5-18H44c0 9.942 7.838 18 17.5 18z"
										opacity=".5"
									/>
									<path d="M121.617 100.152c-5.348-5.739-15.354-14.372-15.354-42.652 0-21.48-15.079-38.674-35.411-42.893v-5.76C70.852 3.96 66.888 0 62 0s-8.852 3.961-8.852 8.846v5.761C32.816 18.826 17.737 36.02 17.737 57.5c0 28.28-10.006 36.913-15.354 42.652-1.66 1.783-2.397 3.915-2.383 6.002C.03 110.688 3.593 115 8.885 115h106.23c5.292 0 8.857-4.313 8.885-8.846.014-2.087-.723-4.221-2.383-6.002z" />
								</g>
							</svg>
						</div>
						<p>
							Salli ilmoitukset niin mitkään tärkeät viestit, tiedotteet ja
							kyselyt eivät mene sinulta ohi.
						</p>
						<RequestPermissionToggle />
					</div>
				</div>
				<div className="slide">
					<div className="slide-content">
						<div className="icon">
							<svg
								width="105"
								height="117"
								viewBox="0 0 105 117"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M105 82.034V5.466C105 2.438 102.492 0 99.377 0H22.499C10.074 0 0 9.8 0 21.875v72.917c0 12.075 10.08 21.875 22.5 21.875h76.877v-3.646a5.454 5.454 0 01-4.259 0c-.98-3.512-.98-13.51 0-17.022L105 82.034zM30 30.526c0-.753.63-1.365 1.4-1.365h49.695c.77 0 1.4.612 1.4 1.365v4.561a1.388 1.388 0 111.365 0H31.407a1.388 1.388 0 11-1.365 0v-4.561H30zm0 14.583c0-.752.63-1.365 1.4-1.365h49.695c.77 0 1.4.613 1.4 1.365v4.562a1.388 1.388 0 111.365 0H31.407a1.388 1.388 0 11-1.365 0v-4.562H30zm59.39 56.974H22.5c-4.148 0-7.502-3.26-7.502-7.291 0-4.014 3.377-7.292 7.501-7.292H89.39v14.583z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</div>
						<p>
							Terpistä löydät yleiset ohjeistukset työterveydestä palkanmaksuun
							ja työvaatteisiin.
						</p>
					</div>
				</div>
				<div className="slide">
					<div className="slide-content">
						<div className="icon">
							<svg
								width="98"
								height="98"
								viewBox="0 0 98 98"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M94.44 4.711L74.532.118a4.594 4.594 0 00-5.255 2.667L60.09 24.222a4.594 4.594 0 001.314 5.365l11.602 9.493C66.121 53.756 54.07 65.974 39.08 73.006l-9.493-11.602a4.594 4.594 0 00-5.365-1.314L2.785 69.278a4.594 4.594 0 00-2.667 5.255l4.593 19.906A4.595 4.595 0 009.187 98C58.204 98 98 58.278 98 9.188a4.594 4.594 0 00-3.56-4.476z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</div>
						<p>
							Kollegan yhteystiedot hukassa? Terpistä löydät kätevästi koko
							henkilöstön yhteystiedot.
						</p>
					</div>
				</div>
				<div className="slide">
					<div className="slide-content">
						<div
							className="icon"
							style={{ background: 'transparent', borderRadius: '0' }}
						>
							<ICON_CHECK_WHITE_IMG style={{ background: 'transparent' }} />
						</div>
						<p>Tervetuloa Terppiin!</p>
					</div>
				</div>
				{/* <div className="carousel-nav">• • •</div> */}
			</TinySlider>
		</div>
	);
};

const OnBoarding = ({ endOnboarding, user }) => {

	const newsettings = {
		lazyload: false,
		loop: false,
		nav: true,
		controls: true,
		controlsText: ['‹', '›'],
		arrowKeys: false,
		mouseDrag: true,
		speed: 1000,
		autoPlay: false,
		autoplayHoverPause: true,
		navPosition: 'bottom',
	};

	return (
		<div className="container login-container">
			<div className="c-onboarding">
				<AppHeader />
				<div className="c-page-header">
					<h1>Terve {user.first_name}!</h1>
				</div>
				<Carousel settings={newsettings} endOnboarding={endOnboarding} />				
				<button className="skip" onClick={endOnboarding}>
					Ohita
				</button>
			</div>
		</div>
	);
};

export default OnBoarding;
