/* eslint-disable eqeqeq */
import React, { useState, useEffect } from 'react';
import { apiClient } from '../apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import BackButton from '../Components/common/BackButton';

import { config } from '../config';
import { Link, useHistory } from 'react-router-dom';
import {
	getPollOptions,
	// getFeedItemType,
	getFeedItemPriorityType,
	FEEDITEM_TYPE_QUESTION,
	FEEDITEM_TYPE_POLL,
	// FEEDITEM_TYPE_SIMPLE,
	FEEDITEM_PRIORITY_ALERT,
	FEEDITEM_PRIORITY_MESSAGE,
	FEEDITEM_PRIORITY_LOW,
	ConditionalWrapper,
	textWithLineBreaks,
	// isAdmin,
} from '../Helpers/common';
import {
	ICON_UNREAD_IMG,
	ICON_READ_IMG,
	ICON_PRIO_ALERT_IMG,
	ICON_PRIO_MESSAGE_IMG,
	ICON_PRIO_LOW_IMG
} from '../Helpers/icons';
import { FeedTimestamp } from '../Components/common/Timestamp';

export default function Feed({ feedItemId }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const [priorityFilter, setPriorityFilter] = useState(1);
	const { feed } = state;
	// const { data: currentUser } = user;

	const changePriorityFilter = (e) => {
		setPriorityFilter(e.target.value);
	};

	const fetchX = (key) => {
		if (!state[key].loading) {
			dispatch({ type: `${key.toUpperCase()}_LOADING` });
			apiCall.GET(`${key}`);
		}
	};

	useEffect(() => {
		let isMounted = true;
		fetchX('feed');

		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const feedItem = (feed.items ?? []).find((i) => i.id == parseInt(feedItemId));

	if (feedItem) {
		return (
			<div className="c-page">
				<BackButton />
				<div className="c-messages m-single">
					<Message feedItem={feedItem} />
				</div>
			</div>
		);
	}

	const filteredFeed =
		feed.items.filter((i) => {
			return i.priority_id >= priorityFilter;
		}) ?? [];

	return (
		<div className="c-messages">
			{feed.loading && <Loader />}
			{feed && (
				<>
					{/* <div className="show-all">
						<button>Näytä kaikki viestit</button>
					</div> */}
					<div style={{ textAlign: 'center' }}>
						<div className="show-all">
							<select
								className="filter-post-by-priority"
								onChange={changePriorityFilter}
							>
								<option value="1">Näytä kaikki viestit</option>
								<option value="3">Näytä vain tärkeät</option>
							</select>
						</div>
					</div>
					{[...filteredFeed].reverse().map((item) => {
						return <Message feedItem={item} listMode={true} key={item.id} />;
					})}
				</>
			)}

			<div className="end-of-list">...</div>
		</div>
	);
}

export const Message = ({ feedItem, listMode }) => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	if (!feedItem) {
		return <Loader className=""></Loader>;
	}

	const baseUrl = '/feed';
	// const typeObj = getFeedItemType(feedItem.priority_id);
	const priorityTypeObj = getFeedItemPriorityType(feedItem.priority_id);

	var className = `${priorityTypeObj?.class ?? ''} ${
		feedItem.is_published ? 'is_published' : 'not_published'
	} ${feedItem.is_sent ? 'is_sent' : 'not_sent'}`;

	const itemType = parseInt(feedItem.type_id);
	const hasImg = feedItem?.thumb_id ?? false;
	const feedImg = hasImg ? (
		<img
			src={config.apiUrl + '/file/' + hasImg + (listMode ? '/preset=feedv2' : '')}
			alt=" "
			loading="lazy"
			className="thumb mini"
		/>
	) : (
		<></>
	);

	const ack = (data, callback) => {
		dispatch({ type: 'ACK_SAVE' });
		apiCall
			.POSTRAW(`feed/${feedItem.id}/ack`, 'ack_save', data)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'ACK_SAVE_SUCCESS', payload: response });
					apiCall.GET_ACK();
					if (callback && typeof callback == 'function') {
						callback();
					}
				} else {
					dispatch({ type: 'ACK_SAVE_FAIL', payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: 'ACK_SAVE_FAIL' });
			});
	};

	const ackItem = state?.ack?.items.find((a) => {
		return a.feed_id === feedItem.id;
	});

	if (itemType === FEEDITEM_TYPE_QUESTION) {
		return (
			<QuestionMessage
				feedItem={feedItem}
				listMode={listMode}
				className={className}
				feedImg={feedImg}
				ack={ack}
				ackItem={ackItem}
			/>
		);
	}

	if (itemType === FEEDITEM_TYPE_POLL) {
		return (
			<PollMessage
				feedItem={feedItem}
				listMode={listMode}
				className={className}
				feedImg={feedImg}
				ack={ack}
				ackItem={ackItem}
			/>
		);
	}

	// if (itemType === FEEDITEM_TYPE_SIMPLE) {
	// }
	return (
		<SimpleMessage
			feedItem={feedItem}
			listMode={listMode}
			className={className}
			feedImg={feedImg}
			baseUrl={baseUrl}
			ack={ack}
			ackItem={ackItem}
		/>
	);
};

const SimpleMessage = ({
	feedItem,
	listMode,
	className,
	feedImg,
	baseUrl,
	onClick,
	ack,
	ackItem,
}) => {
	useEffect(() => {
		if (!listMode && !feedItem.ack_required) {
			ack();
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const selectedOptionId = ackItem?.ack_data == 'OK';
	var history = useHistory();

	const showAckButton =
		!listMode && !!feedItem.ack_required && selectedOptionId == false;
	const showOKButton = !listMode && !showAckButton;

	if (!listMode) console.log(feedItem);

	return (
		<ConditionalWrapper
			condition={listMode}
			wrapper={(children) => (
				<Link to={`${baseUrl}/${feedItem.id}`} key={feedItem.id}>
					{children}
				</Link>
			)}
		>
			<article className={`item basic ${className}`}>
				{/* <FeedTimestamp timestamp={feedItem.updated_at} /> */}
				<header>
					<div className="item-icons">
						<PriorityStatus item={feedItem} />
						{feedItem.attachment.length > 0 && <span className="icon">📎</span>}
					</div>
					<FeedTimestamp timestamp={feedItem.updated_at} />
				</header>

				{feedImg}
				<div className="feed-message">
					<h4>
						<strong>{feedItem?.title ?? ''}</strong>
					</h4>
					<div
						dangerouslySetInnerHTML={{
							__html: textWithLineBreaks(feedItem?.message ?? ''),
						}}
					></div>
				</div>

				{!listMode && feedItem.attachment.length > 0 && (
					<div className="file-list">
						{feedItem.attachment.map((file) => (
							<a
								key={file.uuid}
								href={file.publicUrl}
								target="_blank"
								rel="noreferrer noopener"
							>
								{file.name}
							</a>
						))}
					</div>
				)}

				{showAckButton && (
					<div className="button">
						<button
							className="c-button light"
							onClick={() => ack('OK', history.goBack())}
						>
							Kuittaa viesti luetuksi
						</button>
					</div>
				)}
				{showOKButton && (
					<div className="button">
						<button className="c-button light" onClick={() => history.goBack()}>
							OK
						</button>
					</div>
				)}
				<div className="footer">
					<FeedTimestamp timestamp={feedItem.updated_at} />
					{listMode && <ReadStatus item={feedItem} />}
				</div>
			</article>
			{/* <AdminLink to={`/admin/feed/${feedItem.id}`} /> */}
		</ConditionalWrapper>
	);
};

const PollMessage = ({
	feedItem,
	// listMode,
	className,
	feedImg,
	ack,
	ackItem,
}) => {
	// const options = feedItem?.content_json ?? [];
	const selectedOptionId = ackItem?.ack_data?.poll ?? false;
	const pollOptions = getPollOptions(feedItem);
	const hasAnswered = selectedOptionId !== false;

	return (
		<>
			<article className={`item quiz ${className}`}>
				{feedImg}
				<div className="padded-content">
					<h2>{feedItem.title}</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: textWithLineBreaks(feedItem?.message ?? ''),
						}}
					></p>
					<div className={`buttons ${hasAnswered ? 'm-answered' : ''}`}>
						{pollOptions.map((i) => (
							<button
								key={i.id}
								className={`c-button ${
									selectedOptionId == i.id ? 'm-active' : ''
								}`}
								onClick={(e) => {
									e.preventDefault();
									if (!hasAnswered) {
										ack({ poll: i.id });
									}
								}}
							>
								<div
									className="bar"
									style={{ width: `${i.percentage}%` }}
								></div>
								{i.title} {hasAnswered && ` ${i.percentage}%`}
							</button>
						))}
					</div>
				</div>
			</article>
			{/* <AdminLink to={`/admin/feed/${feedItem.id}`} /> */}
		</>
	);
};

const QuestionMessage = ({
	feedItem,
	listMode,
	className,
	feedImg,
	ack,
	ackItem,
}) => {
	const onSubmit = (e) => {
		e.preventDefault();
		ack(answer);
	};

	// const { state, dispatch } = useStore();
	const [answer, setAnswer] = useState('');

	// const loading = state?.ack?.loading ?? false;
	const showForm = !ackItem;

	return (
		<>
			<article className={`item form ${className}`}>
				{feedImg}
				<div className="padded-content">
					<h2>{feedItem.title}</h2>
					<p
						dangerouslySetInnerHTML={{
							__html: textWithLineBreaks(feedItem?.message ?? ''),
						}}
					></p>
					{showForm && (
						<form className="c-form" onSubmit={onSubmit}>
							<input
								type="text"
								value={answer}
								onChange={(e) => setAnswer(e.target.value)}
							/>
							<div className="buttons">
								<button className="c-button" type="submit">
									Lähetä
								</button>
							</div>
						</form>
					)}
					{/* {!showForm && loading && <Loader />} */}
					{!showForm && (
						<p>
							<strong>Kiitos!</strong>
						</p>
					)}
				</div>
			</article>
			{/* <AdminLink to={`/admin/feed/${feedItem.id}`} /> */}
		</>
	);
};

const ReadStatus = ({ item }) => {
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);

	const ack = state?.ack?.items.find((a) => {
		return a.feed_id === item.id;
	});

	if (!ack) {
		return <ICON_UNREAD_IMG className="c-read-status" />;
	}

	return <ICON_READ_IMG className="c-read-status" />;
};

const PriorityStatus = ({ item }) => {
	return (
		<>
			{item.priority_id == FEEDITEM_PRIORITY_ALERT && (
				<ICON_PRIO_ALERT_IMG className="c-priority-status" />
			)}
			{item.priority_id == FEEDITEM_PRIORITY_MESSAGE && (
				<ICON_PRIO_MESSAGE_IMG className="c-priority-status" />
			)}
			{item.priority_id == FEEDITEM_PRIORITY_LOW && <ICON_PRIO_LOW_IMG />}
		</>
	);
};

/* 
const AdminLink = (props) => {
	const { state } = useStore();
	const { user } = state;
	const { data: currentUser } = user;
	if (!isAdmin(currentUser)) return <></>;
	return (
		<Link {...props} className="admin-link">
			{props.children ? props.children : <ICON_PEN_IMG />}
		</Link>
	);
};
*/