/* eslint-disable eqeqeq */
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { apiClient } from '../apiCall';
import { useStore } from '../../store/useStore';

import { Message } from './Feed';
import { ContactLink } from './Contacts';


export default function Search({ isOpen, toggleSearch }) {
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);
	// const { users, feed } = state;

	//TODO: make search case-insensitive
	const [searchFilter, setSearchFilter] = useState('');

	const resultsUsers = (state?.users?.items ?? []).filter(
		(u) =>
			searchFilter != '' &&
			(u?.first_name?.includes(searchFilter) ||
				u?.last_name?.includes(searchFilter) ||
				u?.phone?.includes(searchFilter) ||
				u?.email?.includes(searchFilter))
	);

	const resultsFeed = (state?.feed?.items ?? []).filter((i) => {
		return (
			searchFilter != '' &&
			(i.title.includes(searchFilter) || i.message.includes(searchFilter))
		);
	});

	const resultsManual = (state?.pages?.items ?? []).filter((i) => {
		return (
			searchFilter != '' &&
			(i?.title?.rendered.includes(searchFilter) ||
				i?.content?.rendered.includes(searchFilter))
		);
	});

	return (
		<div className={`c-search ${isOpen ? 'm-open' : 'm-closed'}`}>
			<button
				className="close"
				onClick={toggleSearch}
				aria-label="Sulje"
			></button>
			<h1>Etsi sisällöstä</h1>
			<input
				type="text"
				onChange={(e) => setSearchFilter(e.target.value)}
				value={searchFilter}
				placeholder="Etsi"
			/>

			{resultsManual.length > 0 && (
				<div className="c-messages">
					<h3>Ohjeistukset</h3>
					<ul className="c-manual">
						{resultsManual.map((page) => {
							return (
								<li key={page.id}>
									<Link to={`/manual/${page.slug}`}>{page.title.rendered}</Link>
								</li>
							);
						})}
					</ul>
				</div>
			)}
			{resultsFeed.length > 0 && (
				<div className="c-messages">
					<h3>Viestit</h3>
					{resultsFeed.map((item) => {
						return <Message feedItem={item} listMode={true} key={item.id} />;
					})}
				</div>
			)}
			{resultsUsers.length > 0 && (
				<div className="contacts">
					<h3>Käyttäjät</h3>
					{resultsUsers.map((item) => {
						return (
							<ContactLink user={item} onClick={toggleSearch} key={item.id} />
						);
					})}
				</div>
			)}
		</div>
	);
}
