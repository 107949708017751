import React, { useState } from 'react';
import PhoneInput from 'react-phone-number-input/input';
import { useHistory } from 'react-router-dom';
import { useStore } from '../../store/useStore';
import axios from 'axios';
import { config } from '../config';
import Loader from '../Components/common/Loader';

export default function LoginForm({
	redirectTo,
	customCallback,
	minimalDisplay,
	hideForgotPassword,
}) {
	var history = useHistory();
	const { state, dispatch } = useStore();
	const { user } = state;
	const [formState, setFormState] = useState('enter-phone');

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: 'LOGINFORM_INPUT', key: name, value: value }, [dispatch]);
	};

	const requestLogin = () => {
		dispatch({ type: 'REQUEST_LOGIN' });

		axios({
			url: config.apiUrl + '/auth/loginwithphone',
			method: 'post',
			data: {
				data: {
					phone: user.phone,
				},
			},
		})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'REQUEST_LOGIN_SUCCESS', payload: response });
					setFormState('enter-code');
				} else {
					dispatch({ type: 'REQUEST_LOGIN_FAIL' });
				}
			})
			.catch((error) => {
				if (error?.response?.status === 401) {
					setFormState('wrong-phone');
				}
				dispatch({ type: 'REQUEST_LOGIN_FAIL' });
			});
	};

	const loginWithCode = () => {
		dispatch({ type: 'LOGIN' });

		axios({
			url: config.apiUrl + '/auth/login',
			method: 'post',
			data: {
				data: {
					phone: user.phone,
					code: user.code,
				},
			},
		})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'LOGIN_SUCCESS', payload: response });
					history.push(redirectTo ? redirectTo : '/');
					// setFormState('enter-code');
				} else {
					dispatch({ type: 'LOGIN_FAIL', payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: 'LOGIN_FAIL' });
			});
	};

	return (
		<>
			<div className="loginContainer">
				{formState === 'enter-phone' && (
					<EnterPhone
						user={user}
						onSubmit={(e) => {
							e.preventDefault();
							e.stopPropagation();
							requestLogin();
						}}
						changeInputValue={changeInputValue}
						setFormState={setFormState}
					/>
				)}

				{formState === 'wrong-phone' && (
					<WrongPhone
						user={user}
						onRetry={(e) => {
							e.preventDefault();
							e.stopPropagation();
							setFormState('enter-phone');
						}}
					/>
				)}

				{formState === 'enter-code' && (
					<EnterCode
						user={user}
						onSubmit={(e) => {
							e.preventDefault();
							e.stopPropagation();
							loginWithCode();
						}}
						changeInputValue={changeInputValue}
					/>
				)}
			</div>
		</>
	);
}

const EnterPhone = ({ user, onSubmit, changeInputValue, setFormState }) => {
	return (
		<div className="loginForm">
			<div className="c-page-header">
				<h1>Tervetuloa Terppiin</h1>
				<p>
					Aloita kirjautumalla sisään antamalla puhelinnumerosi. Saat
					tekstiviestillä kirjautumiskoodin, joten sinun ei tarvitse muistaa
					salasanoja.
				</p>
			</div>

			<form className="c-form narrow" onSubmit={onSubmit}>
				<label className="label-input">
					<span>Puhelinnumero</span>
					{/* <input
						type="phone"
						name="phone"
						autoComplete="phone"
						required
						onChange={(e) => {
							changeInputValue(e);
						}}
					/> */}
					{/* <input readOnly value={'+358'} /> */}
					<PhoneInput
						international={true}
						country="FI"
						defaultCountry={'FI'}
						value={user.phone}
						withCountryCallingCode={true}
						onChange={(val) => {
							changeInputValue({ target: { name: 'phone', value: val } });
						}}
					/>
				</label>

				<div className="buttons">
					{user.loading && <Loader></Loader>}
					{!user.loading && (
						<div>
							<button className="c-button" type="submit">
								Lähetä
							</button>
						</div>
					)}
				</div>
			</form>
		</div>
	);
};

const EnterCode = ({ user, onSubmit, changeInputValue }) => {
	return (
		<div className="loginForm">
			<div className="c-page-header">
				<h1>Kirjautumiskoodi</h1>
				<p>Koodi lähetetty numeroon {user.phone}.</p>
				<p>Klikkaa tekstiviestissä olevaa linkkiä tai kirjoita koodi alle.</p>
			</div>

			<form className="c-form narrow" onSubmit={onSubmit}>
				<label className="label-input">
					<span>Koodi</span>
					<input
						type="code"
						name="code"
						autoComplete="code"
						required
						value={user.code}
						onChange={(e) => {
							changeInputValue(e);
						}}
					/>
				</label>

				<div className="buttons">
					{user.loading && <Loader></Loader>}
					{!user.loading && (
						<button type="submit" className="c-button">
							Lähetä
						</button>
					)}
				</div>
			</form>
		</div>
	);
};

const WrongPhone = ({ user, onRetry }) => {
	return (
		<div className="loginForm">
			<div className="c-page-header">
				<h1>Hupsista</h1>
				<p>
					Puhelinnumeroasi '{user.phone}' ei löytynyt listoiltamme. Tarkista
					tuliko numero näppäiltyä oikein.
				</p>

				<button onClick={onRetry} className="c-button">
					Yritä uudelleen
				</button>
			</div>
		</div>
	);
};
