/* eslint-disable eqeqeq */
/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line no-unused-vars
import React from 'react';

export const isUser = (user) => user?.role_id == 1;
export const isAdmin = (user) => user?.role_id == 2;

export const textWithLineBreaks = (text) => {
	return text
		? `<p>${text.replace(/\n{2}/g, '</p><p>').replace(/\n/g, '<br />')}</p>`
		: '';
};

export function formatPrice(price = '') {
	const parts = parseFloat(price).toString().split('.');
	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
	return parts.join('.');
}

export function formatPriceWithCurrency(price, currency) {
	if (!currency) currency = '€';
	const formattedPrice = price ? formatPrice(price) : '0';
	return `${formattedPrice} ${currency}`.replace(/ /g, '\u00a0');
}

export function formatInputDecimal(input) {
	return input ? input.replace(',', '.') : input;
}

export const convertToPlainText = (html) => {
	const tempElement = document.createElement("div");
	tempElement.innerHTML = html;
	return tempElement.textContent || tempElement.innerText || "";
};
export const shortenText = (text, maxLength) => {
	if (text.length <= maxLength) {
		return text;
	}
	return text.slice(0, maxLength) + "...";
};


export const USER_TYPE_USER = 1;
export const USER_TYPE_ADMIN = 2;

export const USER_ROLES = [
	{
		id: USER_TYPE_USER,
		name: 'Käyttäjä',
		type: 'user',
	},
	{
		id: USER_TYPE_ADMIN,
		name: 'Ylläpitäjä',
		type: 'admin',
	},
];

export const FEEDITEM_TYPE_SIMPLE = 1;
export const FEEDITEM_TYPE_POLL = 2;
export const FEEDITEM_TYPE_QUESTION = 3;

export const FEEDITEM_TYPES = [
	{
		id: FEEDITEM_TYPE_SIMPLE,
		name: 'Viesti',
	},
	{
		id: FEEDITEM_TYPE_POLL,
		name: 'Äänestys',
	},
	{
		id: FEEDITEM_TYPE_QUESTION,
		name: 'Kysymys',
	},
];

export const FEEDITEM_PRIORITY_LOW = 1;
export const FEEDITEM_PRIORITY_MESSAGE = 2;
export const FEEDITEM_PRIORITY_ALERT = 3;

export const FEEDITEM_PRIORITIES = [
	{
		id: FEEDITEM_PRIORITY_LOW,
		name: 'Alhainen',
		description: 'Ilmestyy vain applikaatioon',
		class: 'priority-low',
	},
	{
		id: FEEDITEM_PRIORITY_MESSAGE,
		name: 'Normaali',
		description: 'Lähetetään notifikaationa',
		class: 'priority-message',
	},
	{
		id: FEEDITEM_PRIORITY_ALERT,
		name: 'Korkea',
		description: 'Lähetetään tekstiviestinä ja notifikaationa',
		class: 'priority-alert',
	},
];

export const FeedItemPriorityType = ({ id }) => {
	let tmp = FEEDITEM_PRIORITIES.find((i) => i.id === parseInt(id));
	return tmp ? tmp.name : '---';
};
export const FeedItemType = ({ id }) => {
	let tmp = FEEDITEM_TYPES.find((i) => i.id === parseInt(id));
	return tmp ? tmp.name : '---';
};
export const getFeedItemType = (id) => {
	let tmp = FEEDITEM_TYPES.find((i) => i.id === parseInt(id));
	return tmp ? tmp : false;
};
export const getFeedItemPriorityType = (id) => {
	let tmp = FEEDITEM_PRIORITIES.find((i) => i.id === parseInt(id));
	return tmp ? tmp : false;
};

export const ConditionalWrapper = ({ condition, wrapper, children }) =>
	condition ? wrapper(children) : children;

export const getPollOptions = (feedItem) => {
	var pollOptions = false;
	if (feedItem.type_id == FEEDITEM_TYPE_POLL) {
		pollOptions = (feedItem?.content_json ?? []).map((i) => {
			return {
				...i,
				count: feedItem.ack.filter((a) => {
					return a.ack_data.poll == i.id;
				}).length,
			};
		});

		var totalCount =
			pollOptions?.reduce((acc, cur) => {
				return acc + cur.count;
			}, 0) ?? 0;

		pollOptions = pollOptions.map((i) => {
			return {
				...i,
				percentage:
					totalCount < 1 ? 0 : Math.round((i.count / totalCount) * 100),
			};
		});

		return pollOptions;
	}

	return false;
};
