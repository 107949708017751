import React, { Suspense } from 'react';
import { ToastContainer } from 'react-toastify';

import App from './App';

import Loader from './Components/common/Loader';
import { BrowserRouter } from 'react-router-dom';

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// You can also log the error to an error reporting service
		console.error(error, info);
		// logErrorToMyService(error, info);
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return (
				<div
					className="errorFrame"
					onClick={() => {
						window.location.href = '/';
					}}
				>
					<h1>Valitettavasti jotain meni mönkään</h1>
					<p>
						Odota hetki ja lataa sivu uudelleen.
						<br />
						Voit myös kokeilla toista selainta.
					</p>
					<button className="c-button">OK</button>
				</div>
			);
		}
		return this.props.children;
	}
}

const AppContainer = () => {
	return (
		<ErrorBoundary>
			<BrowserRouter
				onUpdate={() => {
					window.scrollTo(0, 0);
				}}
			>
				<Suspense fallback={<Loader />}>
					<App />
				</Suspense>
			</BrowserRouter>
			<ToastContainer draggable />
		</ErrorBoundary>
	);
};

export default AppContainer;
