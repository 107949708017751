/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import { Redirect, Route, Switch, Link } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { apiClient } from '../../apiCall';
import { NavTab } from 'react-router-tabs';

import {
	CustomInput,
	CustomSwitch,
	CustomSelect,
	CustomPictureSelector,
	CustomRichTextArea,
	createHandlers,
	CustomCheckbox,
	CustomCheckboxes,
} from '../../Components/common/CustomInput';

import {
	FEEDITEM_TYPES,
	FEEDITEM_PRIORITIES,
	FEEDITEM_TYPE_QUESTION,
	FEEDITEM_TYPE_SIMPLE,
	FEEDITEM_TYPE_POLL,
	getPollOptions,
} from '../../Helpers/common';

import Loader from '../../Components/common/Loader';
import GenericSortable from '../../Components/admin/GenericSortable';
import UsersList from '../../Components/admin/UsersList';
import AnswerList from '../../Components/admin/AnswerList';
import FileManager from '../../Components/admin/FileManager';

export default function FeedItem(props) {
	const { state, dispatch } = useStore();
	// const { feeditem: currentFeedItem } = state;
	const isUploading = state.common.isUploading;
	const apiCall = apiClient(state, dispatch);
	const [data, setData] = useState({});
	const [redirect, setRedirect] = useState(false);
	const formRef = useRef();
	const fileSelectorRef = useRef();
	const mediaSelectorRef = useRef();
	const { id, match } = props;

	// const ENDPOINT_SINGULAR = 'feed';
	const ENDPOINT_PLURAL = 'feed';

	useEffect(() => {
		let isMounted = true;
		if (id) {
			apiCall.GET_FEEDITEM(id).then((response) => {
				if (response.status === 200) {
					if (isMounted) {
						setData({
							...response.data.data,
						});
					}
				}
			});
		}
		return () => {
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const {
		handleOnChangeForCheckBoxes,
		handleOnChange,
		handleOnCustomChange,
		// handleOnChangeArray,
		handleSwitch,
		handleOnCKEditorChange,
		sendFile,
		sendMedia,
	} = createHandlers(data, setData, apiCall, state);

	const customErrors = [];

	const sendSubmit = (e) => {
		return onSubmit(e, 'queue');
	};

	/* const sendNow = (e) => {
		return onSubmit(e, 'now');
	}; */
	const onSubmit = (e, send) => {
		const form = formRef.current;
		const isValid = form && form.checkValidity();
		const isCustomValid = customErrors.length === 0;

		if (id) {
			if (isValid) {
				e.preventDefault();
				if (isCustomValid) {
					var saveData = { ...data };

					if (send) {
						saveData['send'] = send;
					}
					apiCall.EDIT(ENDPOINT_PLURAL, id, saveData).then((response) => {
						if (response.status === 200) {
							apiCall.GET_FEEDITEM(data.id);
						}
					});
				} else {
					window.scrollTo({ top: 0, behavior: 'smooth' });
				}
			}
		}
	};

	const remove = () => {
		if (window.confirm('Haluatko varmasti poistaa tämän?')) {
			apiCall.DELETE(ENDPOINT_PLURAL, data.id).then(() => {
				apiCall.GET(ENDPOINT_PLURAL);
				setRedirect(true);
			});
		}
	};

	const groupsOptions = (state?.groups?.items ?? []).map((i) => {
		return {
			id: i.id,
			name: i.name,
		};
	});

	if (redirect === true) {
		return <Redirect to={`/admin/${ENDPOINT_PLURAL}`} />;
	}

	if ((id && !data.id) || state.feed.loading || data.loading) {
		return <Loader className="dark"></Loader>;
	}

	const groupIds = (data?.group ?? []).map((a) => a.id);
	const potentialReceivers = state.users.items
		.filter((u) => {
			return u.is_published == 1;
		})
		.filter((u) => {
			if (data.group && data.group.length > 0) {
				const userGroupIds = u.group.map(i => i.id);
				return userGroupIds.some(r => groupIds.includes(r));
				// return groupIds.includes(u.group_id);
			} else {
				return true;
			}
		});

	const pollOptions = getPollOptions(data);

	const ackUserIds = (data?.ack ?? []).map((a) => a.user_id);

	return (
		<div className="company-form">
			<header>
				<div className="inner">
					<div className="top">
						<h1>
							<span className="category">
								<Link to={'/admin/feed'} className="">
									Viesti /
								</Link>
								#{data.id}
							</span>
							{data.title === '' || data.title === null
								? 'Nimeämätön viesti '
								: `${data.title}`}
						</h1>
					</div>
				</div>
			</header>
			<div className="header-bottom">
				<nav>
					<ul className="max-width">
						<li>
							<NavTab to={`${match.url}/info`}>Yleisinfo</NavTab>
						</li>
						<li>
							<NavTab to={`${match.url}/ack`}>Kuittaukset / Vastaukset</NavTab>
						</li>
					</ul>
				</nav>
			</div>

			<Switch>
				<Route
					exact
					path={`${match.path}`}
					render={() => <Redirect replace to={`${match.url}/info`} />}
				/>

				<Route exact path={`${match.url}/ack`}>
					<section>
						<fieldset id="perustiedot" className="ack-results">
							<legend>Kuittaukset / vastaukset:</legend>

							{data.type_id == FEEDITEM_TYPE_SIMPLE && (
								<>
									<label>
										<span className="label">
											Kuittauksia yhteensä: {data.ack.length} kpl
										</span>
									</label>
									<UsersList
										preFilter={(u) => ackUserIds.includes(u.id)}
										hideHeader
									/>
								</>
							)}

							{data.type_id == FEEDITEM_TYPE_QUESTION &&
								data.type_id == FEEDITEM_TYPE_POLL && (
									<label>
										<span className="label">
											Vastauksia yhteensä: {data.ack.length} kpl
										</span>
									</label>
								)}

							{data.type_id == FEEDITEM_TYPE_POLL &&
								pollOptions &&
								pollOptions.map((i) => {
									return (
										<>
											<div className="quiz-result">
												<p key={i.id}>
													<strong>{i.title}:</strong> {i.percentage}% ({i.count}{' '}
													)
												</p>
												<div
													className="bar"
													style={{ width: `${i.percentage}%` }}
												></div>
											</div>
										</>
									);
								})}

							{/* {data.type_id == FEEDITEM_TYPE_QUESTION &&
								data &&
								data.ack &&
								data.ack.map((i) => {
									return <span key={i.id}>{i.ack_data}</span>;
								})} */}
							{data.type_id == FEEDITEM_TYPE_QUESTION && (
								<>
									<label>
										<span className="label">
											Vastauksia yhteensä: {data.ack.length} kpl
										</span>
									</label>
									<AnswerList
										dashboardView={true}
										items={data.ack}
										// preFilter={(u) => ackUserIds.includes(u.id)}
										hideHeader
									/>
								</>
							)}
						</fieldset>
					</section>
				</Route>

				<Route exact path={`${match.url}/info`}>
					<section>
						<form onSubmit={onSubmit} ref={formRef}>
							<fieldset id="perustiedot">
								<legend>Viestityyppi:</legend>
								<div className="multi-input-wrapper">
									<div className="input-wrapper">
										<CustomSelect
											label={'Viestin tyyppi'}
											field={'type_id'}
											handleOnChange={handleOnChange}
											data={data}
											options={FEEDITEM_TYPES}
										/>
									</div>
									<div className="input-wrapper">
										<CustomSelect
											label={'Prioriteetti'}
											field={'priority_id'}
											handleOnChange={handleOnChange}
											data={data}
											options={FEEDITEM_PRIORITIES}
										/>
									</div>
								</div>
							</fieldset>
							<fieldset>
								<legend>Viestin sisältö:</legend>
								<div className={`grid-flex two`}>
									<div>
										<div className="input-wrapper">
											<CustomInput
												label={'Viestin otsikko'}
												field={'title'}
												handleOnChange={handleOnChange}
												data={data}
											/>
										</div>
										<div className="input-wrapper">
											{/* <CustomInput
												label={'Viestin sisältö'}
												field={'message'}
												useTextarea={true}
												handleOnChange={handleOnChange}
												data={data}
											/> */}
											<CustomRichTextArea
												label={'Viestin sisältö'}
												field={'message'}
												useTextarea={true}
												handleOnChange={handleOnCKEditorChange}
												data={data}
											/>
										</div>
									</div>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<div className="input-wrapper">
											<label>
												<span className="label">Kuva</span>
											</label>
											<CustomPictureSelector
												data={data}
												field={'thumb_id'}
												handleOnChange={sendFile}
												fileSelectorRef={fileSelectorRef}
												autocomplete={'photo'}
												isRequired={false}
												isUploading={isUploading}
											/>
										</div>
									</div>
								</div>
							</fieldset>
							<fieldset>
								<legend>Liitetiedostot:</legend>
								<div>
									<FileManager
										data={data}
										field={'attachment'}
										upload={sendMedia}
										handleOnChange={handleOnCustomChange}
										fileSelectorRef={mediaSelectorRef}
										// autocomplete={'photo'}
										isRequired={false}
										isUploading={isUploading}
										className={'contain'}
									/>
								</div>
							</fieldset>

							{data.type_id == FEEDITEM_TYPE_POLL && (
								<fieldset>
									<legend>Kyselyn vaihtoehdot:</legend>
									<div className="input-wrapper">
										<PollOptions data={data} handleOnChange={handleOnChange} />
									</div>
								</fieldset>
							)}
							{data.type_id == FEEDITEM_TYPE_SIMPLE && (
								<fieldset>
									<legend>Muut asetukset</legend>
									<div className="input-wrapper">
										<CustomCheckbox
											subLabel={'Pyydä kuittaus'}
											field={'ack_required'}
											handleOnChange={handleSwitch}
											data={data}
										/>
									</div>
								</fieldset>
							)}

							<fieldset>
								<legend>Rajaa kohdeyleisöä</legend>

								<CustomCheckboxes
									subLabel={'Käyttäjäryhmät'}
									field={'group'}
									handleOnChange={handleOnChangeForCheckBoxes}
									data={data}
									options={groupsOptions}
								/>

								{/* <div className="input-wrapper">
									<CustomCheckbox
										subLabel={'Lähetä viesti heti'}
										field={'send_now'}
										handleOnChange={handleSwitch}
										data={data}
									/>
								</div> */}

								<label>
									<span className="label">
										Vastaanottajia: ~{potentialReceivers.length} kpl
									</span>
								</label>
							</fieldset>

							<fieldset id="tila">
								<legend>Tila</legend>

								{/* <p>
									{data.is_published == 0 && <span>Luonnos</span>}
									{data.is_published == 1 && <span>Julkaistu</span>},{' '}
								</p> */}
								{data.id && (
									<>
										<div className="input-wrapper">
											<CustomInput
												label={'Ajastettu'}
												field={'scheduled_for'}
												handleOnChange={handleOnChange}
												disabled={true}
												data={data}
											/>
										</div>
										<div className="input-wrapper">
											{/* <CustomInput
												label={'Lähetetty'}
												field={'is_sent'}
												handleOnChange={handleOnChange}
												placeholder={'---'}
												disabled={true}
												data={data}
											/> */}
											<label>
												<span className="label">Lähetetty</span>
												<input
													type="text"
													autoComplete="off"
													name="is_sent"
													placeholder="---"
													disabled={true}
													value={`${
														data.is_sent === 0
															? 'Ei'
															: data.is_sent === 1
															? 'Kyllä'
															: data.is_sent === -1
															? 'Kyllä*'
															: '---'
													}`}
												/>
											</label>
										</div>
										<br />
										<div className="input-wrapper">
											<CustomSwitch
												label={'Julkaistu?'}
												field={'is_published'}
												handleOnChange={handleSwitch}
												disabled={!data.is_sent}
												data={data}
											/>
										</div>
									</>
								)}
							</fieldset>
						</form>

						<fieldset>
							<legend>Toiminnot</legend>
							<div className="grid-flex two">
								<div>
									{data.id && (
										<button
											type="button"
											className="button delete"
											onClick={remove}
										>
											Poista?
										</button>
									)}
								</div>
								<div>
									{data.loading && <Loader></Loader>}
									{!data.loading && (
										<>
											<button
												type="submit"
												className="button"
												form="place-form"
												onClick={onSubmit}
											>
												{data.loading
													? ''
													: data.id
													? 'Tallenna'
													: 'Lisää viesti'}
											</button>
											{data.id && data.is_sent == 0 && (
												<button
													type="submit"
													className="button"
													form="place-form"
													onClick={sendSubmit}
												>
													Tallenna & Lähetä viesti
												</button>
											)}
										</>
									)}
								</div>
							</div>
						</fieldset>
					</section>
				</Route>
			</Switch>

			{/* <div className="submit-holder">
				<button
					type="submit"
					className="button"
					form="place-form"
					onClick={onSubmit}
				>
					{data.loading && <Loader></Loader>}
					{data.loading ? '' : data.id ? 'Tallenna muutokset' : 'Lisää kohde'}
				</button>
			</div> */}
		</div>
	);
}

const PollOptions = ({ data, field, handleOnChange }) => {
	// const editData = (event, id) => {
	// 	const { name, value } = event.target;

	// 	const existingItem = data[field].find((item) => {
	// 		return item.id == id;
	// 	});
	// 	existingItem[name] = value;
	// 	const items = replaceOrAdd([...rawItems], existingItem);

	// 	handleOnChange({
	// 		name: field,
	// 		value: items,
	// 	});
	// };

	const contentEdit = (event) => {
		const { name, value } = event;
		data[name] = value;

		const newOption = {
			target: { name: 'content_json', value: data.content_json },
		};
		handleOnChange(newOption, data.id);
	};

	return (
		<div className={'module module-faq'}>
			<label className="label">Vastausvaihtoehdot:</label>
			<GenericSortable
				field={'content_json'}
				data={data}
				addNewLabel={'Lisää vaihtoehto'}
				handleOnChange={contentEdit}
				EditableComponent={PollOption}
			/>
		</div>
	);
};

const PollOption = ({ item, handleOnChange }) => {
	return (
		<div className="multi-input-wrapper">
			<div className="input-wrapper">
				<CustomInput
					// label={'Vaihtoehto'}
					placeholder={'Vaihtoehdon nimi'}
					field={'title'}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div>
			{/* <div className="input-wrapper">
				<CustomInput
					label={'Vastaus'}
					field={'text'}
					useTextarea={true}
					handleOnChange={(event) => handleOnChange(event, item.id)}
					data={item}
				/>
			</div> */}
		</div>
	);
};
