import React, { useState } from 'react';
import { apiClient } from '../apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import Navigation from '../Components/common/Navigation';

// import { useHistory } from 'react-router-dom';

export default function FeedbackForm() {
	// var history = useHistory();
	const { state, dispatch } = useStore();
	const { feedback } = state;
	// const { form } = feedback;
	const [formState, setFormState] = useState('feedback');

	const apiCall = apiClient(state, dispatch);

	const changeInputValue = (e) => {
		let name = e.target.name;
		let value = e.target.value;

		dispatch({ type: 'FEEDBACKFORM_INPUT', key: name, value: value }, [
			dispatch,
		]);
	};

	const handleSwitch = (event) => {
		const { name } = event.target;
		const bool = !(feedback?.form[name] ?? false) === true ? 1 : 0;
		dispatch({ type: 'FEEDBACKFORM_INPUT', key: name, value: bool }, [
			dispatch,
		]);
	};

	const resetForm = () => {
		dispatch({ type: 'FEEDBACKFORM_RESET', value: {} });
		setFormState('feedback');
	};

	const onSubmit = (e) => {
		dispatch({ type: 'FEEDBACKFORM' });
		e.preventDefault();
		e.stopPropagation();

		apiCall
			.POSTRAW('feedback', 'feedbackform', feedback.form)
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'FEEDBACKFORM_SUCCESS', payload: response });
					setFormState('thanks');
				} else {
					dispatch({ type: 'FEEDBACKFORM_FAIL', payload: response });
				}
			})
			.catch((Error) => {
				dispatch({ type: 'FEEDBACKFORM_FAIL' });
			});
	};

	return (
		<div className="feedbackContainer">
			<Navigation />
			<div className="feedbackForm">
				{formState === 'feedback' && (
					<>
						<div className="c-page-header">
							<h1>Palaute</h1>
							<p>
								Anna kehitysehdotuksia tai jaa risuja ja ruusuja. Kaikki
								palautteet käsitellään luottamuksellisesti ja anonyymisti. Voit
								halutessasi lähettää viestin omalla nimelläsi.
							</p>
						</div>

						<form className="c-form" onSubmit={onSubmit}>
							<label className="label-input">
								<span>Viestisi</span>
								<textarea
									name="message"
									value={feedback?.form?.message ?? ''}
									onChange={(e) => {
										changeInputValue(e);
									}}
								/>
							</label>
							<label className="c-checkbox">
								<input
									type="checkbox"
									name="public"
									value="1"
									onChange={(e) => {
										handleSwitch(e);
									}}
								/>
								<span>
									<span />
									Haluan antaa palautteeni omalla nimellä
								</span>
							</label>

							<div className="buttons">
								{feedback.loading && <Loader></Loader>}
								{!feedback.loading && (
									<div>
										<button className="c-button" type="submit">
											Lähetä
										</button>
									</div>
								)}
							</div>
						</form>
					</>
				)}

				{formState !== 'feedback' && (
					<div className="c-page-header">
						<h1>Kiitos palautteesta!</h1>
						{/* <p>
							Anna kehitysehdotuksia tai jaa risuja ja ruusuja. Kaikki
							palautteet käsitellään luottamuksellisesti ja anonyymisit. Voit
							halutessasi lähettää viestin omalla nimelläsi.
						</p> */}
						<p>
							<button className="c-button" onClick={resetForm}>
								Lähetä uusi palaute
							</button>
						</p>
					</div>
				)}
			</div>
		</div>
	);
}
