import React, { useEffect, useState } from 'react';
import {
	useHistory,
	useLocation,
	Switch,
	Route,
	Redirect,
} from 'react-router-dom';

import axios from 'axios';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { formatISO9075 } from 'date-fns';
import { toast, Flip } from 'react-toastify';

import { config } from './config';
import { useStore } from '../store/useStore';
import { apiClient } from './apiCall';
import { useTracking } from './Helpers/useTracking';
import { messaging } from '../init-fcm';
import { AdminRoute } from './AdminRoute';

import Navigation from './Components/common/Navigation';
import LoginForm from './Views/LoginForm';
import Manual from './Views/Manual';
import Contacts from './Views/Contacts';
import FeedbackForm from './Views/FeedbackForm';
import Feed from './Views/Feed';
import Profile from './Views/Profile';
import { SVG_LOGO } from './Helpers/icons';
import Loader from './Components/common/Loader';
import OnBoarding from './Views/OnBoarding';
import AppHeader from './Components/AppHeader';

import 'react-toastify/dist/ReactToastify.css';
import './Styles/App.scss';
import Footer from './Components/Footer';

function App() {
	const { state, dispatch } = useStore();
	const { user } = state;
	useTracking('G-KHD96PER1E'); // TODO: ADD ANALYTICS?

	const { pathname } = useLocation();
	var history = useHistory();


	if (messaging) {
		messaging.onMessage(function (payload) {
			// Messages received. Either because the
			// app is running in the foreground, or
			// because the notification was clicked.
			// `payload` will contain your data.

			const notification = payload.notification;
			// const notification = payload.data;
			toast(`${notification.title}`, {
				position: toast.POSITION.TOP_CENTER,
				transition: Flip,
				onClick: () => {
					// console.log(window, notification);
					history.push('/');
				},
			});
		});
	}

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	useEffect(() => {
		let search = window.location.search;
		let params = new URLSearchParams(search);
		let loginAction = params.get('login');

		if (loginAction) {
			let d = atob(loginAction).split(';');
			let phone = d[0];
			let code = d[1];
			dispatch({ type: 'LOGIN' });
			axios({
					url: config.apiUrl + '/auth/login',
					method: 'post',
					data: {
						data: {
							phone: phone,
							code: code,
						},
					},
				})
				.then((response) => {
					if (response.status === 200) {
						dispatch({ type: 'LOGIN_SUCCESS', payload: response });
						history.push('/');
					} else {
						dispatch({ type: 'LOGIN_FAIL', payload: response });
					}
				})
				.catch((Error) => {
					dispatch({ type: 'LOGIN_FAIL' });
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const loggedIn = user.loggedIn;
	const loading = user.loading;

	// Focus visible
	document.documentElement.classList.add('focus-visible');

	const onBlur = (e) => {
		e.target.classList.remove('focus-visible');
	};

	document.body.addEventListener('keyup', (e) => {
		var key = e.which || e.keyCode;

		if (key === 9) {
			const activeElement = document.activeElement;

			if (!activeElement.classList.contains('focus-visible')) {
				activeElement.classList.add('focus-visible');
				activeElement.addEventListener('blur', onBlur);
			}
		}
	});

	return (
		<>
			{loading && <Splash />}
			<Switch>
				<Route
					path="/admin"
					render={({ match: { url } }) => {
						return <AdminRoute url={url} />;
					}}
				/>
				{!loggedIn && <LoggedOutStack />}
				{loggedIn && <LoggedInStack />}
				<Route path="*" render={() => <Redirect to="/" />} />
			</Switch>
			<Footer />
		</>
	);
}

const Splash = () => {
	return (
		<div className="c-splash">
			<SVG_LOGO />
			<Loader />
		</div>
	);
};

const LoggedOutStack = () => {
	return (
		<>
			<Route path="/" exact>
				<div className="container login-container">
					<AppHeader />
					<LoginForm />
				</div>
			</Route>
		</>
	);
};

const PageHeader = ({ user }) => {
	return (
		<div className="c-page-header">
			<h1>Hei {user.first_name}!</h1>
			<Navigation mobile="true" />
		</div>
	);
};

const LoggedInStack = () => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const { user } = state;
	const { data: currentUser } = user;

	const [needsOnboarding, setNeedsOnboarding] = useState(
		user.data.needs_onboarding
	);

	var history = useHistory();

	const fetchX = (key) => {
		if (!state[key].loading) {
			dispatch({ type: `${key.toUpperCase()}_LOADING` });

			apiCall.GET(`${key}`);
		}
	};

	const requiredData = ['users', 'feed', 'groups', 'ack', 'pages'];

	useEffect(() => {
		let isMounted = true;
		requiredData.forEach((item) => {
			fetchX(item);
		});
		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const endOnboarding = () => {
		apiCall
			.POSTRAW(`users/${user.data.id}`, 'user', {
				last_onboarding: formatISO9075(new Date()),
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'USER_REFRESH', payload: response });
				}
			});

		setNeedsOnboarding(false);
	};

	const reEnableOnboarding = () => {
		apiCall
			.POSTRAW(`users/${user.data.id}`, 'users', {
				last_onboarding: null,
			})
			.then((response) => {
				if (response.status === 200) {
					dispatch({ type: 'USER_REFRESH', payload: response });
				}
				history.push('/');
			});
		setNeedsOnboarding(true);
	};

	// if (needsOnboarding) {
	// 	return (
	// 		<Route path="/" exact>
	// 			<OnBoarding endOnboarding={endOnboarding} user={currentUser} />
	// 		</Route>
	// 	);
	// }

	return (
		<>
			<SwitchTransition mode={'out-in'}>
				<CSSTransition
					key={needsOnboarding}
					addEndListener={(node, done) => {
						node.addEventListener('transitionend', done, false);
					}}
					classNames="fade"
				>
					<>
						{needsOnboarding && (
							<OnBoarding endOnboarding={endOnboarding} user={currentUser} />
						)}
						{!needsOnboarding && (
							<div className="app-container">
								<div className="container">
									<AppHeader />
									<Route
										path="/"
										exact
										render={({
											match: {
												params: { id },
											},
											match,
										}) => {
											return (
												<>
													<PageHeader user={currentUser} />
													<Feed />
												</>
											);
										}}
									/>
									<Route
										path="/feed/:id"
										render={({
											match: {
												params: { id },
											},
											match,
										}) => {
											return (
												<>
													<PageHeader user={currentUser} />
													<Feed feedItemId={id}></Feed>
												</>
											);
										}}
									/>
									<Route
										path="/manual/:id?"
										render={({
											match: {
												params: { id },
											},
											match,
										}) => {
											return <Manual pageId={id}></Manual>;
										}}
									/>
									<Route
										path="/contacts/:id?"
										render={({
											match: {
												params: { id },
											},
										}) => {
											return <Contacts contactId={id}></Contacts>;
										}}
									/>
									<Route path="/feedback">
										<FeedbackForm />
									</Route>
									<Route path="/profile">
										<Profile reEnableOnboarding={reEnableOnboarding} />
									</Route>
									{/* <Route path="*" render={() => <Redirect to="/" />} /> */}
									{/* <DebugNav /> */}
								</div>
							</div>
						)}
					</>
				</CSSTransition>
			</SwitchTransition>
		</>
	);
};

export default App;

/*
const DebugNav = () => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const loggedIn = state.user.loggedIn;

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	const links = !loggedIn
		? [{ name: 'login', href: '/' }]
		: [
				{ name: 'feed', href: '/' },
				{ name: 'manual', href: '/manual' },
				{ name: 'contacts', href: '/contacts' },
				{ name: 'feedback', href: '/feedback' },
				{ name: 'profile', href: '/profile' },
				{ name: 'admin', href: '/admin' },
		  ];

	return (
		<ul className="debug-links">
			{loggedIn && (
				<li>
					<button onClick={logoutAction}>[Log out]</button>
				</li>
			)}
			{links &&
				links.map((i) => (
					<li key={i.href}>
						<Link to={i.href}>{i.name}</Link>
					</li>
				))}
		</ul>
	);
}; */
