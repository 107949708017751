import { config } from '../App/config';
import { toast } from 'react-toastify';
import JWT from '../App/Helpers/JWT';

const localStorageKey = config.localStoragePrefix + 'user';

const defaultState = {
	phone: config.isDev ? config.devPhone : '',
	code: '',
	loading: false,
	loggedIn: false,
	notificationsLoading: false,
	token: null,
	data: null,
};

export const userInitialState = (() => {
	let state = window.localStorage.getItem(localStorageKey)
		? {
				...defaultState,
				...JSON.parse(window.localStorage.getItem(localStorageKey)),
		  }
		: defaultState;
	state.notificationsLoading = false;
	state.loading = false;
	return state;
})();

export const userReducer = (state = userInitialState, action) => {
	let user = state;
	switch (action.type) {
		case 'LOGINFORM_INPUT':
			var ns = { ...state, [action.key]: action.value };

			user = ns;
			break;

		case 'LOGIN':
			user = {
				...state,
				loggedIn: false,
				loading: true,
				token: null,
			};
			break;

		case 'AUTH/EXCHANGE-TOKEN_GET':
		case 'LOGIN_SUCCESS':
		case 'USER_REFRESH':
			let data = JWT.getUserFromToken(action.payload.headers.authentication);
			user = {
				...state,
				loggedIn: data !== undefined,
				loading: false,
				token:
					data !== undefined ? action.payload.headers.authentication : null,
				data: data !== undefined ? data : null,
			};
			break;
		case 'LOGIN_FAIL':
			toast.error(
				'Kirjautuminen epäonnistui, tarkista tunnuksesi ja yritä uudelleen'
			);
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
				data: null,
			};
			break;

		case 'REQUEST_LOGIN':
			user = { ...state, loading: true };
			break;

		case 'REQUEST_LOGIN_SUCCESS':
			console.debug(`SMS CODE:`, `${action.payload.data.debug_code}`);
			console.debug(`SMS LINK:`, `${action.payload.data.debug_link}`);
			if (config.isDev) {
				toast.info(`SMS CODE: ${action.payload.data.debug_code}`);
			}
			// toast.info(`SMS LINK: ${action.payload.data.debug_link}`);
			user = { ...state, loading: false };
			break;

		case 'REQUEST_LOGIN_FAIL':
			user = { ...state, loading: false };
			break;

		case 'CHANGE_NOTIFICATION_SETTINGS':
			user = { ...state, notificationsLoading: true };
			break;
		case 'CHANGE_NOTIFICATION_SETTINGS_FAIL':
		case 'CHANGE_NOTIFICATION_SETTINGS_SUCCESS':
			user = { ...state, notificationsLoading: false };
			break;

		case 'LOGOUT':
			user = {
				...state,
				loggedIn: false,
				loading: false,
				token: null,
				data: null,
				notificationsLoading: false,
			};
			window.localStorage.setItem(localStorageKey, JSON.stringify(user));

			if (!action.silent) {
				window.location = '/';
			}
			break;

		default:
			user = state;
			break;
	}

	const { code, ...storeData } = user;
	window.localStorage.setItem(localStorageKey, JSON.stringify(storeData));

	return user;
};
