export const config = {
	localStoragePrefix: '',
	isDev: process.env.REACT_APP_ENV !== 'production',
	devPassword: '',
	devEmail: '',
	devPhone: '+358407760362',
	apiUrl:
		process.env.REACT_APP_ENV === 'production'
			? 'https://terppi-api.trpgroup.fi/api/v1'
			: process.env.REACT_APP_ENV === 'staging'
			? 'https://terppi-api.atk.dqcomms.com/staging/v1'
			: 'http://terppi-api.atk7.dqcomms.com:81/api/v1',

	mapsApiKey: '',
	siteUrl:
		process.env.REACT_APP_ENV === 'production'
			? 'https://terppi.trpgroup.fi'
			: process.env.REACT_APP_ENV === 'staging'
			? 'https://terppi.trpgroup.fi'
			: 'localhost:3000',
	maxFileSize: 10 * 1024 * 1024,
	localeCompare: {
		locale: 'fi'
	},
	firebase: {
		apiKey: 'AIzaSyCEhIh_KmtGBYL6Z1YH89IawtUb1H1_UtU',
		authDomain: 'trp-test-f3cbc.firebaseapp.com',
		databaseURL: 'https://trp-test-f3cbc.firebaseio.com',
		projectId: 'trp-test-f3cbc',
		storageBucket: 'trp-test-f3cbc.appspot.com',
		messagingSenderId: '556129729531',
		appId: '1:556129729531:web:0ccd9af9dbdd59242c695e',
		vapid:
			'BGl7_gGAWObCSxz0XFu7d412tiwR9U29KofyEoKXiUnq8dWpYvrkMDi6JwEEbASPuTaRKGhW169PNaxWi0K_GVY',
	},
};
