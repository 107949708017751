import React from 'react';
import { useHistory } from 'react-router-dom';

export default function BackButton(props) {
	var history = useHistory();

	const onClickFn =
		typeof props.onClick == 'function' ? props.onClick : () => history.goBack();

	return (
		<button className="back" aria-label="Takaisin" onClick={onClickFn}>
			<svg
				width="18"
				height="18"
				viewBox="0 0 18 18"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M10.347 16.387l-.892.867a.98.98 0 01-1.362 0l-7.81-7.59a.916.916 0 010-1.324L8.093.746a.98.98 0 011.362 0l.892.867a.92.92 0 01-.016 1.34L5.49 7.438h11.546c.534 0 .964.417.964.937v1.25c0 .52-.43.938-.964.938H5.49l4.84 4.484a.914.914 0 01.017 1.34z"
					fill="#002E85"
					fillRule="nonzero"
				/>
			</svg>
		</button>
	);
}
