import React from 'react';
import { formatRelative, format } from 'date-fns';
import { fi } from 'date-fns/locale';

export default function Timestamp(props) {
	const cleanedTimestamp = props.timestamp
		? props.timestamp.replace(/-/g, "/").replace(/T/g, " ")
		: false;

	if (!cleanedTimestamp || cleanedTimestamp === '0000-00-00 00:00:00') {
		return <time></time>;
	}

	const customFormat = props.format ? props.format : 'dd.MM.yyyy';

	return (
		<time className="timestamp">
			{props.pure &&
				format(new Date(cleanedTimestamp), customFormat, {
					locale: fi,
				})}
			{!props.pure &&
				formatRelative(new Date(cleanedTimestamp), new Date(), {
					locale: fi,
				})}
		</time>
	);
}

export const FeedTimestamp = (props) => {
	const newProps = {
		...props,
		format: 'dd.MM.yyyy HH:mm',
		pure: false,
	};

	return <Timestamp {...newProps} />;
};

export const dateParser = (date) => {
	const parsed = new Date(date);
	if (!Number.isNaN(parsed.valueOf())) {
		return parsed;
	}
	const parts = String(date).match(/\d+/g);
	if (parts == null || parts.length <= 2) {
		return parsed;
	} else {
		const [firstP, secondP, ...restPs] = parts.map((x) => parseInt(x));
		const correctedParts = [firstP, secondP - 1, ...restPs];
		const isoDate = new Date(Date.UTC(...correctedParts));
		return isoDate;
	}
};