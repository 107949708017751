/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import { useStore } from '../../store/useStore';

import { version } from '../../../package.json';

export default function Footer() {
	const { dispatch } = useStore();
	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	return (
		<footer>
			<div>
				<button onClick={logoutAction}>
					Kirjaudu ulos
					{/* <span className="icon">
					<svg
						width="19"
						height="18"
						viewBox="0 0 19 18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M8.153 1.613l.892-.867a.98.98 0 011.362 0l7.81 7.59a.916.916 0 010 1.324l-7.81 7.594a.98.98 0 01-1.362 0l-.892-.867a.92.92 0 01.016-1.34l4.841-4.485H1.464A.949.949 0 01.5 9.626v-1.25c0-.52.43-.938.964-.938H13.01L8.17 2.954a.914.914 0 01-.017-1.34z"
							fill="#FFF"
							fillRule="nonzero"
						/>
					</svg>
				</span> */}
				</button>
				<p className="problems-signing-in">
					<button
						className="m-notabtn"
						onClick={() => window.location.reload()}
					>
						Ongelmia kirjautumisessa? Sulje kaikki välilehdet ja lataa sivu
						uudelleen.
					</button>
				</p>
				<p className="external-links">
					<a target="_blank" href="https://trpgroup.fi/" rel="noopener">
						trpgroup.fi
					</a>
					<span className="version">{version}</span>
				</p>

				{/* <p className="version">
					VERSION:{' '}
					{process.env.REACT_APP_GIT_SHA ||
						process.env.SOURCE_VERSION ||
						process.env.HEROKU_RELEASE_VERSION}
				</p> */}
			</div>
		</footer>
	);
}
