/* eslint-disable eqeqeq */
import React from 'react';
import { NavLink } from 'react-router-dom';
import { useStore } from '../../../store/useStore';
import { isAdmin } from '../../Helpers/common';

export default function Navigation(props) {
	const { state, dispatch } = useStore();
	const { user } = state;
	const { data: currentUser } = user;

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	return (
		<nav
			className={
				props.mobile == 'true' ? 'c-navigation mobile' : 'c-navigation'
			}
		>
			<ul>
				<li className="desktop">
					<NavLink to={'/'} exact activeClassName="active">
						<span className="icon">
							<svg
								width="20"
								height="16"
								viewBox="0 0 20 16"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.621 5.453a.235.235 0 01.379.184v7.988c0 1.035-.84 1.875-1.875 1.875H1.875A1.875 1.875 0 010 13.625V5.641c0-.196.223-.305.379-.184.875.68 2.035 1.543 6.02 4.438.824.601 2.214 1.867 3.601 1.859 1.395.012 2.813-1.281 3.605-1.86C17.59 7 18.746 6.134 19.621 5.455zM10 10.5c.906.016 2.21-1.14 2.867-1.617 5.184-3.762 5.578-4.09 6.774-5.028A.935.935 0 0020 3.117v-.742C20 1.34 19.16.5 18.125.5H1.875C.84.5 0 1.34 0 2.375v.742c0 .29.133.559.36.738 1.195.934 1.59 1.266 6.773 5.028.656.476 1.96 1.633 2.867 1.617z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Viestit
					</NavLink>
				</li>
				<li>
					<NavLink to={'/manual'} activeClassName="active">
						<span className="icon">
							<svg
								width="18"
								height="20"
								viewBox="0 0 18 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M18 14.063V.937C18 .418 17.57 0 17.036 0H3.857C1.727 0 0 1.68 0 3.75v12.5C0 18.32 1.728 20 3.857 20h13.179a.949.949 0 00.964-.938v-.625a.935.935 0 00-.358-.73c-.168-.602-.168-2.316 0-2.918a.922.922 0 00.358-.726zM5.143 5.233c0-.129.108-.234.24-.234h8.519c.132 0 .24.105.24.234v.782a.238.238 0 01-.24.234H5.384a.238.238 0 01-.241-.234v-.782zm0 2.5c0-.129.108-.234.24-.234h8.519c.132 0 .24.105.24.234v.782a.238.238 0 01-.24.234H5.384a.238.238 0 01-.241-.234v-.782zM15.324 17.5H3.857c-.711 0-1.286-.559-1.286-1.25 0-.688.579-1.25 1.286-1.25h11.467a13.674 13.674 0 000 2.5z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Ohjeistukset
					</NavLink>
				</li>
				<li>
					<NavLink to={'/contacts'} activeClassName="active">
						<span className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M19.273.962L15.211.024a.937.937 0 00-1.073.544l-1.875 4.375a.937.937 0 00.268 1.095L14.9 7.975A14.49 14.49 0 017.975 14.9l-1.937-2.368a.937.937 0 00-1.095-.268L.568 14.138a.937.937 0 00-.544 1.073l.938 4.062a.938.938 0 00.913.727C11.878 20 20 11.893 20 1.875a.937.937 0 00-.727-.913z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Yhteystiedot
					</NavLink>
				</li>
				<li>
					<NavLink to={'/feedback'} activeClassName="active">
						<span className="icon">
							<svg
								width="22"
								height="22"
								viewBox="0 0 22 22"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M11.001.792c-9.755 0-14.133 11.21-6.534 16.798v3.618l3.548-1.914c6.666 1.814 13.378-2.73 13.378-9.055.005-5.218-4.65-9.447-10.392-9.447zm1.097 12.673l-2.69-2.716-5.176 2.785L9.91 7.678l2.69 2.716 5.176-2.785-5.677 5.856z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Palaute
					</NavLink>
				</li>
				<li>
					<NavLink to={'/profile'} activeClassName="active">
						<span className="icon">
							<svg
								width="20"
								height="20"
								viewBox="0 0 20 20"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M10 0a6.25 6.25 0 110 12.5A6.25 6.25 0 0110 0zm7.16 13.04l-2.786-.697c-2.927 2.106-6.474 1.636-8.748 0l-2.785.697A3.75 3.75 0 000 16.678v1.447C0 19.161.84 20 1.875 20h16.25C19.161 20 20 19.16 20 18.125v-1.447a3.75 3.75 0 00-2.84-3.638z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Omat tiedot
					</NavLink>
				</li>
				{isAdmin(currentUser) && (
					<li className="desktop">
						<NavLink to={'/admin'} exact activeClassName="active">
							<span className="icon">
								{/* <svg
									width="20"
									height="16"
									viewBox="0 0 20 16"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M19.621 5.453a.235.235 0 01.379.184v7.988c0 1.035-.84 1.875-1.875 1.875H1.875A1.875 1.875 0 010 13.625V5.641c0-.196.223-.305.379-.184.875.68 2.035 1.543 6.02 4.438.824.601 2.214 1.867 3.601 1.859 1.395.012 2.813-1.281 3.605-1.86C17.59 7 18.746 6.134 19.621 5.455zM10 10.5c.906.016 2.21-1.14 2.867-1.617 5.184-3.762 5.578-4.09 6.774-5.028A.935.935 0 0020 3.117v-.742C20 1.34 19.16.5 18.125.5H1.875C.84.5 0 1.34 0 2.375v.742c0 .29.133.559.36.738 1.195.934 1.59 1.266 6.773 5.028.656.476 1.96 1.633 2.867 1.617z"
										fill="#FFF"
										fillRule="nonzero"
									/>
								</svg> */}

								<svg width="28" height="17" xmlns="http://www.w3.org/2000/svg">
									<g fill="currentColor" fillRule="evenodd">
										<rect width="28" height="3" rx="1.5" />
										<rect
											width="28"
											height="3"
											rx="1.5"
											transform="translate(0 7)"
										/>
										<rect
											width="28"
											height="3"
											rx="1.5"
											transform="translate(0 14)"
										/>
									</g>
								</svg>
							</span>
							Hallinta
						</NavLink>
					</li>
				)}
				<li className="desktop">
					<button onClick={logoutAction}>
						<span className="icon">
							<svg
								width="19"
								height="18"
								viewBox="0 0 19 18"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M8.153 1.613l.892-.867a.98.98 0 011.362 0l7.81 7.59a.916.916 0 010 1.324l-7.81 7.594a.98.98 0 01-1.362 0l-.892-.867a.92.92 0 01.016-1.34l4.841-4.485H1.464A.949.949 0 01.5 9.626v-1.25c0-.52.43-.938.964-.938H13.01L8.17 2.954a.914.914 0 01-.017-1.34z"
									fill="#FFF"
									fillRule="nonzero"
								/>
							</svg>
						</span>
						Kirjaudu ulos
					</button>
				</li>
			</ul>
		</nav>
	);
}
