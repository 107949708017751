import React, { useState } from 'react';
import { useStore } from '../../store/useStore';
import Navigation from '../Components/common/Navigation';
import BackButton from '../Components/common/BackButton';

import { config } from '../config';
import { NavLink } from 'react-router-dom';
import { SVG_PERSON } from '../Helpers/icons';
// eslint-disable-next-line no-unused-vars
import { serviceWorkerRegistration } from '../../index';
import Timestamp from '../Components/common/Timestamp';
import RequestPermissionToggle from '../Components/common/RequestPermissionToggle';

export default function Profile({ reEnableOnboarding }) {
	const { state, dispatch } = useStore();
	// const apiCall = apiClient(state, dispatch);

	const [showNotificationDebug, setShowNotificationDebug] = useState(false);

	const { user } = state;

	const hasImg = user?.data?.thumb_id ?? false;

	const logoutAction = () => {
		dispatch({ type: 'LOGOUT' });
	};

	return (
		<div>
			<Navigation />
			<div className="c-page">
				<BackButton />
				<div className="c-profile">
					<div className="c-person profile">
						<div className="row">
							<div className="image">
								{!hasImg && <SVG_PERSON className={""} />}
								{hasImg && (
									<img
										src={config.apiUrl + "/file/" + hasImg + "/preset=thumb"}
										alt="profile"
										loading="lazy"
										className="thumb mini"
									/>
								)}
							</div>
							<div className="text">
								<h1>
									<span className="last">{user?.data?.last_name}</span>{" "}
									{user?.data?.first_name}
								</h1>
								<p>
									<span>{user?.data?.title}</span>
									<br />
									<span>{user?.data?.phone}</span>
									<br />
									<span>{user?.data?.email}</span>
								</p>
							</div>
						</div>
					</div>

					<div className="tools">
						<RequestPermissionToggle loaderClass="dark" />
						{/* <div>
							<label className="c-switch">
								<input
									type="checkbox"
									onClick={togglePermission}
									checked={hasPermission}
									readOnly
									disabled={user.notificationsLoading}
								/>
								<span></span>Salli notifikaatiot
							</label>
						</div>
						{user.notificationsLoading && (
							<div>
								<label>
									<Loader className={'dark'} />
								</label>
							</div>
						)} */}
						<div>
							<button
								style={{ fontSize: "0.9em", textDecoration: "none" }}
								onClick={() => setShowNotificationDebug(!showNotificationDebug)}
							>
								Ongelmia notifikaatioiden kanssa?
							</button>
							{showNotificationDebug && (
								<div>
									<p>
										<strong>Tuki notifikaatioille:</strong>
										<br />
										{typeof Notification !== "undefined" &&
										"Notification" in window
											? "Kyllä"
											: "Ei"}
									</p>
									<p>
										<strong>Notifikaatioiden tila:</strong>
										<br />
										{typeof Notification !== "undefined" &&
											(Notification?.permission ?? "-")}
									</p>
									{/* <p>
										<label>
											<strong>Tallennettu notifikaatiodata:</strong>
										</label>
										<span
											style={{
												margin: 0,
												wordBreak: "break-word",
												fontSize: "0.8em",
											}}
										>
											{user?.data?.notification_settings ?? "-"}
										</span>
									</p> */}
								</div>
							)}
						</div>

						<div>
							<NavLink activeClassName="active" to={"/feedback"}>
								<button>Jätä palautetta &raquo;</button>
							</NavLink>
						</div>
						<div>
							<button onClick={reEnableOnboarding}>
								Ohjeet & Onboardaus &raquo;
							</button>
						</div>
					</div>
					<div className="last-login">
						Viimeisin kirjautuminen:{" "}
						<Timestamp timestamp={user?.data?.last_login} />
					</div>
					<div className="buttons">
						<button className="signout-button" onClick={logoutAction}>
							<span className="icon">
								<svg
									width="19"
									height="18"
									viewBox="0 0 19 18"
									xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M8.153 1.613l.892-.867a.98.98 0 011.362 0l7.81 7.59a.916.916 0 010 1.324l-7.81 7.594a.98.98 0 01-1.362 0l-.892-.867a.92.92 0 01.016-1.34l4.841-4.485H1.464A.949.949 0 01.5 9.626v-1.25c0-.52.43-.938.964-.938H13.01L8.17 2.954a.914.914 0 01-.017-1.34z"
										fill="#FFF"
										fillRule="nonzero"
									/>
								</svg>
							</span>
							Kirjaudu ulos
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
