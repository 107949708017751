import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';

import { Link, useHistory } from 'react-router-dom';
import { apiClient } from '../../apiCall';
import { config } from '../../config';
import Loader from '../common/Loader';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import { HeaderAmount, HeaderAdd } from './HeaderAmount';

import {
	FeedItemType,
	FeedItemPriorityType,
} from '../../Helpers/common';

export default function FeedList({ dashboardView, tableConfig, preFilter }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [filter, setFilter] = useState();
	var history = useHistory();

	const onRowClick = (row) => {
		history.push('/admin/feed/' + row.original.id);
	};

	const { feed: dataSource } = state;
	const { items } = dataSource;

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},

			// {
			// 	Header: 'Vastaanottaja',
			// 	accessor: 'target_group',
			// },
			{
				Header: 'Tyyppi',
				accessor: 'type_id',
				Cell: ({ value }) => {
					return <FeedItemType id={value} />;
				},
			},
			{
				Header: 'Kuva',
				accessor: 'thumb', // accessor is the "key" in the data
				Cell: ({ value, row }) => {
					if (!value) return '';

					return (
						<Link
							to={{
								pathname: '/admin/feed/' + row.original.id,
							}}
						>
							<img
								src={config.apiUrl + '/file/' + value.id + '/preset=thumb'}
								alt=" "
								loading="lazy"
								className="thumb mini"
							/>
						</Link>
					);
				},
			},
			// {
			// 	Header: 'Tunniste',
			// 	accessor: 'title',
			// },
			// {
			// 	Header: 'Viesti',
			// 	accessor: 'message',
			// },
			{
				Header: 'Viesti',
				accessor: 'message',
				Cell: ({ value, row }) => {
					return (
						<>
							<p className="message-title">{row.original.title}</p>
							<p className="message-value">{value}</p>
						</>
					);
				},
			},

			{
				Header: 'Taso',
				accessor: 'priority_id',
				Cell: ({ value }) => {
					return <FeedItemPriorityType id={value} />;
				},
			},

			// {
			// 	Header: 'Kuittaukset',
			// 	accessor: 'modified_at',
			// 	Cell: ({ value }) => {
			// 		return <span>x/x</span>;
			// 	},
			// },
			{
				Header: 'Ajastettu',
				accessor: 'scheduled_for',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			{
				Header: 'Tila',
				accessor: 'is_published',
				Cell: ({ value, row }) => {
					return (
						<span>
							{value
								? 'Julkaistu'
								: row.original.scheduled_for
								? 'Odottaa lähetystä'
								: 'Luonnos'}
						</span>
					);
				},
			},

			// {
			// 	Header: 'Muokattu',
			// 	accessor: 'updated_at',
			// 	Cell: ({ value }) => {
			// 		return <Timestamp timestamp={value} pure={true} />;
			// 	},
			// },
		],
		[]
	);

	const addFeed = () => {
		apiCall.ADD('feed', {}).then((response) => {
			apiCall.GET('feed');
			if (response?.status === 200) {
				history.push('/admin/feed/' + response.data.data.id);
			}
		});
	};

	if (dataSource.loading === true || !dataSource.items) {
		return <Loader />;
	}

	return (
		<section className="common-list">
			<div className="common-list-header">
				<h1>Syöte: Viestit ja Kyselyt</h1>
				<FeedHeader data={processedRows} add={addFeed} />
			</div>
			{!dashboardView && (
				<>
					<input
						placeholder="Etsi"
						name="etsi"
						type="text"
						value={filter || ''}
						onChange={(e) => setFilter(e.target.value)}
					/>
				</>
			)}
			{filter && filter !== '' && <h3>{`Tuloksia hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				filter={filter}
				hidePagination={dashboardView}
				config={tableConfig}
			/>
		</section>
	);
}

export const FeedHeader = (props) => {
	const data = props.data ? props.data : [];
	const isPublished = data.filter((i) => i.is_published === 1);
	// const isSent = data.filter((i) => i.is_sent === 1);

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				<HeaderAmount label="Julkaistu" value={isPublished.length} />
			</div>
			<HeaderAdd add={props.add} />
		</>
	);
};
