import React, { useEffect } from 'react';
import { useLocation, Route, Redirect } from 'react-router-dom';
import { useStore } from '../store/useStore';
import { apiClient } from './apiCall';

import AdminNav from './Components/common/AdminNav';

import UsersList from './Components/admin/UsersList';
import User from './Views/admin/User';
import GroupsList from './Components/admin/GroupsList';
import Group from './Views/admin/Group';
import Debug from './Views/admin/Debug';

import './Styles/Admin.scss';

import FeedList from './Components/admin/FeedList';
import FeedItem from './Views/admin/FeedItem';
import FeedbackList from './Components/admin/FeedbackList';
import FeedbackItem from './Views/admin/FeedbackItem';
import MessageList from './Components/admin/MessageList';

export const AdminPage = ({ children }) => {
	let location = useLocation();

	const classs = location.pathname.replace(/[^a-z0-9]/g, ' ');

	return (
		<div className="admin">
			<AdminNav location={location} />
			<main className={`right ${classs}`}>{children}</main>
		</div>
	);
};

export const AdminRoute = ({ url }) => {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const fetchX = (key) => {
		if (!state[key].loading) {
			dispatch({ type: `${key.toUpperCase()}_LOADING` });
			apiCall.GET(`${key}`);
		}
	};

	const requiredData = [
		'users',
		'feed',
		'feedback',
		'groups',
		'messages',
		// 'pages',
		// 'categories',
		// 'translations',
	];

	useEffect(() => {
		let isMounted = true;
		requiredData.forEach((item) => {
			fetchX(item);
		});
		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// if (state.users.loading) {
	// 	return (
	// 		<main>
	// 			<Loader className="" />
	// 		</main>
	// 	);
	// }

	return (
		<>
			<Route path={`${url}/`} exact>
				<Redirect to={`/admin/feed`} />
			</Route>

			<Route path={`${url}/debug/:id?`} render={({
					match: {
						params: { id },
					},
					match,
				}) => {
					return (
						<AdminPage>
							<Debug id={id} match={match} />
						</AdminPage>
					);}}
				>
			</Route>
			<Route path={`${url}/messages`} exact>
				<AdminPage>
					<MessageList />
				</AdminPage>
			</Route>
			<Route path={`${url}/feed`} exact>
				<AdminPage>
					<FeedList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/feed/:id`}
				render={({
					match: {
						params: { id },
					},
					match,
				}) => {
					return (
						<AdminPage>
							<FeedItem id={id} match={match} />
						</AdminPage>
					);
				}}
			></Route>

			<Route path={`${url}/feedback`} exact>
				<AdminPage>
					<FeedbackList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/feedback/:id`}
				render={({
					match: {
						params: { id },
					},
					match,
				}) => {
					return (
						<AdminPage>
							<FeedbackItem id={id} match={match} />
						</AdminPage>
					);
				}}
			></Route>

			<Route path={`${url}/users`}>
				<AdminPage>
					<UsersList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/user/:id`}
				render={({
					match: {
						params: { id },
					},
					match,
				}) => {
					return (
						<AdminPage>
							<User id={id} match={match} />
						</AdminPage>
					);
				}}
			></Route>

			<Route path={`${url}/groups`}>
				<AdminPage>
					<GroupsList />
				</AdminPage>
			</Route>
			<Route
				path={`${url}/group/:id`}
				render={({
					match: {
						params: { id },
					},
					match,
				}) => {
					return (
						<AdminPage>
							<Group id={id} match={match} />
						</AdminPage>
					);
				}}
			></Route>
		</>
	);
};
