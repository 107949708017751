import React, { useState } from 'react';
import { useHistory, useLocation, Link } from 'react-router-dom';

import { useStore } from '../../store/useStore';
import { SVG_LOGO } from '../Helpers/icons';
import Search from '../Views/Search';

const AppHeader = () => {
	var history = useHistory();

	const [searchOpen, setSearchOpen] = useState(false);
	const { state } = useStore();
	// const apiCall = apiClient(state, dispatch);
	const { user } = state;

	const toggleSearch = (e) => {
		if (e) e.preventDefault();
		setSearchOpen(!searchOpen);
	};

	const location = useLocation();

	React.useEffect(() => {
		setSearchOpen(false);
	}, [location]);

	const showHeaderIcons = user.loggedIn && !user.data.needs_onboarding;

	return (
		<>
			<header
				className={`c-header  ${showHeaderIcons ? 'logged-in' : 'logged-out'}`}
			>
				<button className="back" onClick={() => history.goBack()}>
					<svg
						width="18"
						height="18"
						viewBox="0 0 18 18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M10.347 16.387l-.892.867a.98.98 0 01-1.362 0l-7.81-7.59a.916.916 0 010-1.324L8.093.746a.98.98 0 011.362 0l.892.867a.92.92 0 01-.016 1.34L5.49 7.438h11.546c.534 0 .964.417.964.937v1.25c0 .52-.43.938-.964.938H5.49l4.84 4.484a.914.914 0 01.017 1.34z"
							fill="#FFF"
							fillRule="nonzero"
						/>
					</svg>
				</button>
				{/* <Link className="back"></Link> */}
				<Link className="logo" to="/">
					<SVG_LOGO />
				</Link>

				<button className={`search`} onClick={toggleSearch}>
					<svg
						width="20"
						height="20"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
					>
						<path
							d="M19.727 17.291l-3.894-3.894a.937.937 0 00-.664-.273h-.637a8.084 8.084 0 001.719-5A8.123 8.123 0 008.126 0 8.123 8.123 0 000 8.124a8.123 8.123 0 008.125 8.124c1.886 0 3.62-.64 5-1.718v.636c0 .25.097.489.273.664l3.895 3.895a.934.934 0 001.324 0l1.105-1.106a.942.942 0 00.004-1.328zM8.126 13.124c-2.762 0-5-2.234-5-5 0-2.761 2.234-5 5-5 2.761 0 5 2.235 5 5 0 2.762-2.235 5-5 5z"
							fill="#FFF"
							fillRule="nonzero"
						/>
					</svg>
				</button>
			</header>
			<Search isOpen={searchOpen} toggleSearch={toggleSearch} />
		</>
	);
};
export default AppHeader;
