/* eslint-disable eqeqeq */
import React, { useEffect } from 'react';
import { apiClient } from '../apiCall';
import { useStore } from '../../store/useStore';
import Loader from '../Components/common/Loader';
import Navigation from '../Components/common/Navigation';
import BackButton from '../Components/common/BackButton';

// import { config } from "../config";
import { Link, useHistory } from 'react-router-dom';

const baseUrl = '/manual';

export default function Manual({ pageId }) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);

	const { pages } = state;

	const manualPages = [...(pages?.items ?? [])].sort((a, b) => {
		if (a.title.rendered < b.title.rendered) {
			return -1;
		}
		if (a.title.rendered > b.title.rendered) {
			return 1;
		}
		return 0;
	});

	const fetchX = (key) => {
		if (!state[key].loading) {
			dispatch({ type: `${key.toUpperCase()}_LOADING` });
			apiCall.GET(`${key}`);
		}
	};

	useEffect(() => {
		let isMounted = true;

		fetchX('pages');

		return () => {
			// eslint-disable-next-line no-unused-vars
			isMounted = false;
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const page = manualPages.find((i) => i.slug == pageId);

	// if (pages.loading) {
	// 	return <Loader className=""></Loader>;
	// }

	// if (pageId && !pages.loading && !page) {
	// 	return <Redirect to={`/`} />;
	// }

	// if (!pages.loading && (!pages.items || pages.items.length === 0)) {
	// 	return <Redirect to={`/`} />;
	// }

	return (
		<div>
			<Navigation />
			{pages.loading && <Loader className=""></Loader>}
			{!pages.loading && pageId && (
				<PageTemplate page={page} pages={manualPages} />
			)}
			{!pages.loading && !pageId && (
				<section className="wp-nav">
					<div className="c-page-header">
						<h1>Ohjeistukset</h1>
						<p>Täältä löydät ajantasaiset tiedot yrityksen toimintatavoista.</p>
					</div>

					<div className="c-page article">
						<BackButton />
						{/* <div className="c-article">
							<h1>
								<Link to={baseUrl}>Ohjeistukset</Link>
							</h1>
						</div> */}

						<div className="fixer negateTopMargin">
							<SubPages
								pageId={pageId}
								isLoading={pages.loading}
								pages={manualPages}
							/>
						</div>
					</div>
				</section>
			)}
		</div>
	);
}

const SubPages = ({ isLoading, pages, pageId, level }) => {

	if (!pageId) pageId = 0;
	if (!level) level = 0;

	const filteredPages = pages && pages.filter((i) => (i.parent == pageId || (pageId == 0 && !i.parent) ) );
	// const filteredPages = pages && pages.filter((i) => (i.parent == pageId ) );

	return (
		filteredPages.length > 0 ?
		<ul className={`c-manual ${level > 0 ? 'sub sub-'+level : ''}`}>
			{filteredPages.map((page) => {
						return (
							<li key={page.id}>
								<Link to={`${baseUrl}/${page.slug}`}>
									{page.title.rendered}
								</Link>
								<SubPages
									pageId={page.id}
									isLoading={isLoading}
									pages={pages}
									level={level + 1}
								/>
							</li>
						);
					})}
				{isLoading && <Loader className="dark" />}
			</ul>
			: <></>
	);
};

const PageTemplate = ({ page, pages }) => {
	const history = useHistory();

	// const { state, dispatch } = useStore();
	// const { pages } = state;

	if (!page) {
		return <Loader className=""></Loader>;
	}

	const pageIndex = pages.findIndex((i) => i.id == page.id);

	// const prevArticle = pages.find((i) => i.id < page.id);
	// const nextArticle = pages.find((i) => i.id > page.id);
	const prevArticle = pages[pageIndex - 1];
	const nextArticle = pages[pageIndex + 1];

	let backUrl = `${baseUrl}`;
	const parent = page.parent != 0 ? pages.find(i => page.parent == i.id) : false;

	if (parent) backUrl = `${backUrl}/${parent.slug}`;

	return (
		<>
			<section className="wp-nav">
				<div className="c-page-header">
					{/* <h1>Ohjeistukset</h1>
						<p>Täältä löydät ajantasaiset tiedot yrityksen toimintatavoista.</p> */}

					<h1>
						<Link to={baseUrl}>Ohjeistukset</Link>
						<span> / </span>
						{parent ? (
							<>
								<Link to={parent.slug}>{parent.title.rendered}</Link>
								<span> / </span>
							</>
						) : (
							''
						)}
						<span className="title">{page.title.rendered}</span>
					</h1>
				</div>
			</section>
			<section id={page.slug} className="c-page article wp-page">
				<BackButton onClick={() => history.push(backUrl)} />
				{/* <BackButton  /> */}
				<div className="c-article">
					<div
						dangerouslySetInnerHTML={{ __html: page?.content?.rendered ?? '' }}
					></div>
				</div>
				<div className="fixer negateTopMargin">
					<SubPages pageId={page.id} isLoading={!page} pages={pages} />
				</div>
				<div className="c-article-nav">
					{prevArticle && (
						<Link className="prev" to={`/manual/${prevArticle.slug}`}>
							{prevArticle.title.rendered}
						</Link>
					)}
					{nextArticle && (
						<Link className="next" to={`/manual/${nextArticle.slug}`}>
							{nextArticle.title.rendered}
						</Link>
					)}
				</div>
			</section>
		</>
	);
};

// const PageTemplate = (props) => {
// 	const { state, dispatch } = useStore();
// 	const apiCall = apiClient(state, dispatch);

// 	const data = state.pages.items.find((page) => page.slug === props.slug);

// 	if (!data) {
// 		return <Loader className="dark"></Loader>;
// 	}

// 	// const imageUrl = data.hero
// 	// 	? data.hero.publicUrl + '/preset=hero'
// 	// 	: 'data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=';

// 	// const html = (data?.content_json ?? []).find((i) => i.id == 'test');

// 	// const modules = data?.content_json ?? [];

// 	return (
// 		<section id={page}>

// 		</section>
// 	);
// };
