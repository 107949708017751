import React, { useState } from 'react';
import { useStore } from '../../../store/useStore';
import { isAdmin } from '../../Helpers/common';

import { Link, useHistory } from 'react-router-dom';
import { apiClient } from '../../apiCall';
import { config } from '../../config';
import Loader from '../common/Loader';

import Timestamp from '../common/Timestamp';
import CustomTable from '../common/CustomTable';
import { HeaderAmount, HeaderAdd } from './HeaderAmount';
import {
	ICON_STATUS_LIVE_IMG,
	ICON_STATUS_HIDDEN_IMG,
} from '../../Helpers/icons';
import UserRole from '../common/UserRole';
import { UserGroups } from '../common/UserGroup';

export default function UsersList({
	dashboardView,
	tableConfig,
	preFilter,
	hideHeader,
}) {
	const { state, dispatch } = useStore();
	const apiCall = apiClient(state, dispatch);
	const [filter, setFilter] = useState();
	var history = useHistory();

	const onRowClick = (row) => {
		history.push('/admin/user/' + row.original.id);
	};

	const { users: dataSource } = state;
	const { items } = dataSource;

	const preCustomFilter = preFilter
		? preFilter
		: () => {
				return true;
		  };

	const processedRows = items
		.map((item) => {
			const newItem = { ...item };
			return newItem;
		})
		.filter(preCustomFilter)
		.reverse();

	const data = React.useMemo(() => {
		if (processedRows) {
			if (dashboardView) {
				return processedRows.sort((a, b) => {
					return new Date(b.updated_at) - new Date(a.updated_at);
				});
			} else {
				return processedRows;
			}
		} else {
			return [];
		}
	}, [dashboardView, processedRows]);

	const columns = React.useMemo(
		() => [
			{
				Header: 'ID',
				accessor: 'id',
			},
			{
				Header: 'Kuva',
				accessor: 'thumb', // accessor is the "key" in the data
				Cell: ({ value, row }) => {
					if (!value) return '';

					return (
						<Link
							to={{
								pathname: '/admin/user/' + row.original.id,
							}}
						>
							<img
								src={config.apiUrl + '/file/' + value.id + '/preset=thumb'}
								alt=" "
								loading="lazy"
								className="thumb mini"
							/>
						</Link>
					);
				},
			},
			// {
			// 	Header: 'Etunimi',
			// 	accessor: 'first_name',
			// 	hide: true,
			// 	show: false,
			// },
			{
				Header: 'Nimi',
				accessor: (row) => `${row.first_name} ${row.last_name}`,
				Cell: ({ value, row }) => {
					const user = row.original;
					return (
						<Link
							to={{
								pathname: '/admin/user/' + row.original.id,
							}}
						>
							{`${user.first_name} ${user.last_name}`}
							<br />
							{user.email && (
								<span className="size-desc">{`(${user.email})`}</span>
							)}
						</Link>
					);
				},
			},
			{
				Header: 'Puhnro.',
				accessor: 'phone',
			},
			{
				Header: 'Ryhmä',
				accessor: 'group_id',
				Cell: ({ value, row }) => {					
					// return <UserGroup groupId={row.original.group_id} />;
					return <UserGroups groups={row.original.group} />;
				},
			},
			{
				Header: 'Tyyppi',
				accessor: 'role',
				Cell: ({ value, row }) => {
					return <UserRole userId={row.original.id} />;
				},
			},
			{
				Header: 'Muokattu',
				accessor: 'updated_at',
				Cell: ({ value }) => {
					return <Timestamp timestamp={value} pure={true} />;
				},
			},
			{
				Header: 'Julkaistu?',
				accessor: 'is_published',
				Cell: ({ value }) => {
					return value === 1 ? (
						<ICON_STATUS_LIVE_IMG />
					) : (
						<ICON_STATUS_HIDDEN_IMG />
					);
				},
			},
		],
		[]
	);

	// const columns = React.useMemo(
	// 	() => [
	// 		{
	// 			Header: 'ID',
	// 			accessor: 'id',
	// 		},
	// 		{
	// 			Header: 'Nimi',
	// 			accessor: (row) => `${row.first_name} ${row.last_name}`,
	// 			Cell: ({ value, row }) => {
	// 				const user = row.original;
	// 				return (
	// 					<Link
	// 						to={{
	// 							pathname: '/admin/user/' + row.original.id,
	// 						}}
	// 					>
	// 						{`${user.first_name} ${user.last_name}`}
	// 						<br />
	// 						{user.email && (
	// 							<span className="size-desc">{`(${user.email})`}</span>
	// 						)}
	// 					</Link>
	// 				);
	// 			},
	// 		},

	// 		{
	// 			Header: 'Vastaus',
	// 			accessor: 'created_at',
	// 			Cell: ({ value }) => {
	// 				return <span>VASTAUS</span>;
	// 			},
	// 		},

	// 		{
	// 			Header: 'Muokattu',
	// 			accessor: 'updated_at',
	// 			Cell: ({ value }) => {
	// 				return <Timestamp timestamp={value} pure={true} />;
	// 			},
	// 		},
	// 	],
	// 	[]
	// );

	const addUser = () => {
		apiCall.ADD('users', {}).then((response) => {
			apiCall.GET('users');
			if (response?.status === 200) {
				history.push('/admin/user/' + response.data.data.id);
			}
		});
	};

	if (dataSource.loading === true || !dataSource.items) {
		return <Loader />;
	}

	return (
		<section className="common-list">
			{!hideHeader && (
				<>
					<div className="common-list-header">
						<h1>Käyttäjät</h1>
						<UsersHeader data={processedRows} add={addUser} />
					</div>
					{!dashboardView && (
						<>
							<input
								placeholder="Etsi"
								name="etsi"
								type="text"
								value={filter || ''}
								onChange={(e) => setFilter(e.target.value)}
							/>
						</>
					)}
				</>
			)}
			{filter && filter !== '' && <h3>{`Tuloksia hakusanalla '${filter}'`}</h3>}
			<CustomTable
				data={data}
				columns={columns}
				onRowClick={onRowClick}
				filter={filter}
				hidePagination={dashboardView}
				config={tableConfig}
			/>
		</section>
	);
}

export const UsersHeader = (props) => {
	const data = props.data ? props.data : [];
	// const isPublished = data.filter((i) => i.is_published === 1);

	const admins = data.filter((i) => isAdmin(i));
	const nonAdmins = data.filter((i) => !isAdmin(i));

	return (
		<>
			<div className="common-list-data">
				<HeaderAmount label="Yhteensä" value={data.length} />
				<HeaderAmount label="Ylläpitäjiä" value={admins.length} />
				<HeaderAmount label="Muita käyttäjiä" value={nonAdmins.length} />
			</div>
			<HeaderAdd add={props.add} />
		</>
	);
};
